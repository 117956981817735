import {
  CLEAR_FAQ_DATA,
  CLEAR_FAQ_DATA_BY_ID,
  GET_FAQ_DATA,
  GET_FAQ_DATA_BY_ID,
} from "../actions/FaqActions";

const initialState = {
  faqData: null,
  faqItem: null,
};

const FaqReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_FAQ_DATA: {
      return { ...state, faqData: action.payload };
    }
    case CLEAR_FAQ_DATA: {
      return { ...state, faqData: null };
    }
    case GET_FAQ_DATA_BY_ID: {
      return { ...state, faqItem: action.payload };
    }
    case CLEAR_FAQ_DATA_BY_ID: {
      return { ...state, faqItem: null };
    }
    default: {
      return { ...state };
    }
  }
};

export default FaqReducer;
