import {
  CLEAR_MAPPING_BY_REGION,
  CLEAR_MAPPING_BY_REGION_ID,
  CLEAR_MAPPING_SERVICE,
  CLEAR_MAPPING_SERVICE_BY_ID,
  CLEAR_NOT_MAPPING_SERVICE,
  GET_MAPPING_BY_REGION,
  GET_MAPPING_BY_REGION_ID,
  GET_MAPPING_SERVICE,
  GET_MAPPING_SERVICE_BY_ID,
  GET_NOT_MAPPING_SERVICE,
} from "../actions/MappingActions";

const initialState = {
  mappingService: null,
  mappingServiceById: null,
  notMappingService: null,
  mappingByRegion: null,
  mappingByRegionById: null,
};

const MappingReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_MAPPING_SERVICE: {
      return { ...state, mappingService: action.payload };
    }
    case CLEAR_MAPPING_SERVICE: {
      return { ...state, mappingService: null };
    }
    case GET_MAPPING_SERVICE_BY_ID: {
      return { ...state, mappingServiceById: action.payload };
    }
    case CLEAR_MAPPING_SERVICE_BY_ID: {
      return { ...state, mappingServiceById: null };
    }
    case GET_NOT_MAPPING_SERVICE: {
      return { ...state, notMappingService: action.payload };
    }
    case CLEAR_NOT_MAPPING_SERVICE: {
      return { ...state, notMappingService: null };
    }
    case GET_MAPPING_BY_REGION: {
      return { ...state, mappingByRegion: action.payload };
    }
    case CLEAR_MAPPING_BY_REGION: {
      return { ...state, mappingByRegion: null };
    }
    case GET_MAPPING_BY_REGION_ID: {
      return { ...state, mappingByRegionById: action.payload };
    }
    case CLEAR_MAPPING_BY_REGION_ID: {
      return { ...state, mappingByRegionById: null };
    }
    default: {
      return { ...state };
    }
  }
};

export default MappingReducer;
