import { findUsers } from "api/client/profile.api";
import { getClinicById } from "api/clinic/clinic.api";
import { getLaboratories } from "api/laboratory/laboratory.api";
import {
  EnumProviderTypes,
  genders,
  providerTypes,
  roles,
} from "helpers/constants";

export const handleProviderTypeName = providerType =>
  providerTypes.find(type => type.value === providerType)?.name ||
  providerType ||
  "--- --- ---";

export const handleServiceTypeName = (serviceTypes, serviceType) =>
  serviceTypes?.find(type => type.code === serviceType)?.name ||
  serviceType ||
  "--- --- ---";

export const handleCountryName = (countries, countryCode) => {
  return (
    countries.find(country => country?.code === countryCode)?.name ||
    countryCode ||
    "--- --- ---"
  );
};

export const handleRegionName = (regions, regionCode) => {
  return (
    regions?.find(region => region?.code === regionCode)?.name ||
    regionCode ||
    "--- --- ---"
  );
};

export const handlePriceGroupName = (priceGroups, groupCode) =>
  priceGroups.find(group => group.code === groupCode)?.name ||
  groupCode ||
  "--- --- ---";

export const handleGenderName = genderValue =>
  genders.find(gender => gender.value === genderValue)?.name ||
  genderValue ||
  "--- --- ---";

export const handleRoleName = roleValue =>
  roles.find(role => role.value === roleValue)?.name ||
  roleValue ||
  "--- --- ---";

export const handleLanguageName = (languages, languageCode) =>
  languages?.find(
    language => language.code?.toLowerCase() === languageCode?.toLowerCase(),
  )?.name ||
  languageCode ||
  "--- --- ---";

export const getCurrencyByCountryCode = (countryCode, regionCode) => {
  if (countryCode) {
    switch (countryCode?.toUpperCase()) {
      case "KZ":
        return "₸";
      case "KG":
        return "С";
      default:
        return "";
    }
  } else if (regionCode) {
    return regionCode.includes("KZ")
      ? "₸"
      : regionCode.includes("KG")
        ? "С"
        : "";
  } else {
    return "";
  }
};

export const EnumStatusColors = {
  registered: "#FFBF00",
  payed: "#3085e1",
  completed: "#03a621",
  error: "#ff0000",
  notFound: "#808080",
};

export const handleStatusColor = status => {
  switch (status) {
    case 0:
      return "#FFBF00";
    case 1:
      return "#FFBF00";
    case 2:
      return "#3085e1";
    case 3:
      return "#3085e1";
    case 4:
      return "#3085e1";
    case 5:
      return "#3085e1";
    case 6:
      return "#03a621";
    case 7:
      return "#ff0000";
    case 8:
      return "#808080";
    case 9:
      return "#ff0000";
    case 10:
      return "#3085e1";
    case 11:
      return "#ff0000";
    case 12:
      return "#72e8ab";
    default:
      return "#808080";
  }
};

export const handleGetProviderCode = values => {
  if (values?.providerType && values?.provider) {
    if (values.providerType === EnumProviderTypes.lab) {
      return values.provider.provider || "";
    } else if (values.providerType === EnumProviderTypes.clinic) {
      return values.provider.id || "";
    } else if (values.providerType === EnumProviderTypes.doctor) {
      return values.provider.id || "";
    }
  }

  return "";
};

export const handleGetProviderByProviderType = async (
  providerType,
  provider,
) => {
  if (providerType && provider) {
    if (providerType === EnumProviderTypes.lab) {
      const laboratory = await getLaboratories({ Provider: provider });
      return laboratory.results.length > 0 ? laboratory.results[0] : null;
    } else if (providerType === EnumProviderTypes.clinic) {
      const clinic = await getClinicById(provider);
      return clinic.value;
    } else if (providerType === EnumProviderTypes.doctor) {
      const user = await findUsers({ ProfileId: provider });
      return user.results.length > 0 ? user.results[0] : null;
    } else {
      return null;
    }
  }

  return null;
};
