export const EnumProviderTypes = {
  lab: "Lab",
  clinic: "Clinic",
  doctor: "Doctor",
  client: "Client",
  nurse: "Nurse",
};

export const providerTypes = [
  { name: "Лаборатория", value: EnumProviderTypes.lab },
  { name: "Клиника", value: EnumProviderTypes.clinic },
  { name: "Доктор", value: EnumProviderTypes.doctor },
  { name: "Клиент", value: EnumProviderTypes.client },
  { name: "Медсестра", value: EnumProviderTypes.nurse },
];

export const genders = [
  { name: "Мужской", value: 0 },
  { name: "Женский", value: 1 },
];

export const roles = [
  { name: "Клиент", value: "client" },
  { name: "Доктор", value: "doctor" },
  { name: "Медсестра", value: "nurse" },
  { name: "Регистратор", value: "registrar" },
  { name: "Медицинский консультант", value: "medConsultant" },
  { name: "Сотрудник аптеки", value: "pharmacyPersonal" },
  { name: "Сотрудник фармкомпании", value: "pharmCompany" },
];

export const documentsRoles = [
  { name: "Врач", value: "doctor" },
  { name: "Регистратор", value: "registrar" },
  { name: "Средний медперсонал", value: "nurse" },
  { name: "Сотрудник аптеки", value: "pharmacyPersonal" },
  { name: "Сотрудник фармкомпании", value: "pharmCompany" },
  { name: "Медицинский консультант", value: "medConsultant" },
  { name: "Сотрудник лаборатории", value: "laboratoryPersonal" },
];

export const employeeRoles = roles.filter(role => role.value !== "client");

export const regionAll = { name: "Все регионы", code: "ALL" };
export const countriesAll = { name: "Все страны", code: "ALL" };

export const rowsPerPageOptions = [10, 20, 25, 50, 100, 200, 500, 1000];

export const PAGE_SIZE_ALL = 999999;
