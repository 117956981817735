import { GET_APPLICATIONS } from "../actions/ApplicationServices";

const initialState = {
  applications: null,
};

const ApplicationsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_APPLICATIONS: {
      return { ...state, applications: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export default ApplicationsReducer;
