import xlsx from "json-as-xlsx";
import { toDate } from "../services/date.service";

export const exportXlx = (
  sheetName,
  columns,
  content,
  noDataMessage,
  fileName,
) => {
  let data = [
    {
      sheet: sheetName,
      columns,
      content: content.length > 0 ? content : [noDataMessage],
    },
  ];

  let settings = {
    fileName: `${fileName}_${toDate(new Date(), true)}`,
    extraLength: 3,
    writeMode: "writeFile",
    writeOptions: {},
    RTL: false,
  };

  xlsx(data, settings);
};

export const exportResponse = (response, columns, noDataMessage, fileName) =>
  exportXlx("Response", columns, response, noDataMessage, fileName);

export const handleDownloadFile = (file, fileId, folder) => {
  const fileName = folder && fileId ? `${folder}_${fileId}` : file?.name;

  const link = document.createElement("a");
  link.href = URL.createObjectURL(file);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const handleDownloadFileByLink = (href, fileName) => {
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const openNewWindowWithData = (url, data) => {
  const form = document.createElement("form");
  form.action = url;
  form.method = "POST";

  if (data) {
    for (let key in data) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    }
  }

  document.body.appendChild(form);
  form.submit();
};

export function getOperatingSystemInfo() {
  const userAgent = navigator.userAgent;
  let osName = "";
  let osVersion = "";
  let isMobile = false;

  if (/Android/i.test(userAgent)) {
    osName = "Android";
    const match = userAgent.match(/Android (\d+\.\d+)/);
    if (match) {
      osVersion = match[1];
    }
    isMobile = true;
  } else if (
    /webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
  ) {
    if (/iPad/i.test(userAgent)) {
      osName = "iOS (iPad)";
    } else if (/iPhone/i.test(userAgent)) {
      osName = "iOS (iPhone)";
    } else if (/iPod/i.test(userAgent)) {
      osName = "iOS (iPod)";
    } else if (/webOS/i.test(userAgent)) {
      osName = "webOS";
    } else if (/BlackBerry/i.test(userAgent)) {
      osName = "BlackBerry";
    } else if (/IEMobile/i.test(userAgent)) {
      osName = "Windows Mobile";
    } else if (/Opera Mini/i.test(userAgent)) {
      osName = "Opera Mini";
    }
    const match = userAgent.match(/OS (\d+[._]\d+([._]\d+)?)/);
    if (match) {
      osVersion = match[1].replace(/_/g, ".");
    }
    isMobile = true;
  } else if (/Win/i.test(userAgent)) {
    osName = "Windows";
    const match = userAgent.match(/Windows NT (\d+\.\d+)/);
    if (match) {
      osVersion = match[1];
    }
  } else if (/Mac/i.test(userAgent)) {
    osName = "MacOS";
    const match = userAgent.match(/Mac OS X (\d+[._]\d+([._]\d+)?)/);
    if (match) {
      osVersion = match[1].replace(/_/g, ".");
    }
  } else if (/Linux/i.test(userAgent)) {
    osName = "Linux";
  } else {
    osName = "Unknown";
  }

  return {
    operatingSystem: osName,
    operatingSystemVersion: osVersion,
    isMobile,
  };
}

export const openNewWindow = url => {
  const operatingSystemInfo = getOperatingSystemInfo();
  if (operatingSystemInfo.operatingSystem.toLowerCase().includes("ios"))
    window.open(url);
  else window.open(url, "_blank");
};

export const replaceNullWithEmptyString = obj => {
  for (const key in obj) {
    if (obj[key] === null) obj[key] = "";
  }

  return obj;
};

export const capitalizeFirstLetter = string => {
  if (!string) return string;

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function formatFileSize(sizeInBytes) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;

  if (sizeInBytes < kilobyte) {
    return sizeInBytes + " B";
  } else if (sizeInBytes < megabyte) {
    return (sizeInBytes / kilobyte).toFixed(2) + " KB";
  } else if (sizeInBytes < gigabyte) {
    return (sizeInBytes / megabyte).toFixed(2) + " MB";
  } else {
    return (sizeInBytes / gigabyte).toFixed(2) + " GB";
  }
}

export function generateUniqueFileName({ fileName }) {
  const currentDate = new Date();
  const timestamp = currentDate.getTime();
  const randomString = Math.random().toString(36).substring(2, 8);

  const uniqueFileName = `${timestamp}_${randomString}_${fileName}`;
  return uniqueFileName;
}

export function getFormattedOffset(
  inputDate,
  { withSeconds = false, withSign = true, needPlus = true } = {},
) {
  const offsetMinutes = inputDate.getTimezoneOffset();
  const sign = offsetMinutes > 0 ? "-" : needPlus ? "+" : "";
  const hours = Math.floor(Math.abs(offsetMinutes) / 60);
  const minutes = Math.abs(offsetMinutes) % 60;

  const formattedOffset = `${withSign ? sign : ""}${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}${
    withSeconds ? ":00" : ""
  }`;

  return formattedOffset;
}

export const getDateTimeOffset = date => {
  const inputDate = new Date(date);

  const year = inputDate.getFullYear();
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  const hours = inputDate.getHours().toString().padStart(2, "0");
  const minutes = inputDate.getMinutes().toString().padStart(2, "0");
  const seconds = inputDate.getSeconds().toString().padStart(2, "0");
  const offsetHours = getFormattedOffset(inputDate);

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000${offsetHours}`;

  return formattedDateTime;
};

export const findFirstRouteByPermissionKey = (routes, targetPermissionKey) => {
  let foundPath = null;

  const searchRoutes = routesArray => {
    for (const route of routesArray) {
      if (
        route.element &&
        route.element.props &&
        route.element.props.permissionKey === targetPermissionKey
      ) {
        foundPath = route.path;
        break;
      }
      if (route.children && !foundPath) {
        searchRoutes(route.children);
      }
    }
  };

  searchRoutes(routes);
  return foundPath;
};
