import {
  CLEAR_CAMPAIGN_BY_ID,
  CLEAR_CAMPAIGNS,
  CLEAR_DISCOUNT_AGENT,
  GET_CAMPAIGN_BY_ID,
  GET_CAMPAIGNS,
  GET_DISCOUNT_AGENT,
} from "../actions/CampaignsServices";

const initialState = {
  campaigns: null,
  campaignById: null,
  discountAgent: null,
};

const CampaignsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_CAMPAIGNS: {
      return { ...state, campaigns: action.payload };
    }
    case CLEAR_CAMPAIGNS: {
      return { ...state, campaigns: null };
    }
    case GET_CAMPAIGN_BY_ID: {
      return { ...state, campaignById: action.payload };
    }
    case CLEAR_CAMPAIGN_BY_ID: {
      return { ...state, campaignById: null };
    }
    case GET_DISCOUNT_AGENT: {
      return { ...state, discountAgent: action.payload };
    }
    case CLEAR_DISCOUNT_AGENT: {
      return { ...state, discountAgent: null };
    }
    default: {
      return { ...state };
    }
  }
};

export default CampaignsReducer;
