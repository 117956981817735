import { instanceIdentityAuthed } from "../index";

const endpoint = "api/Users";

export const getAdminUsers = async params => {
  const response = await instanceIdentityAuthed.get(
    `${endpoint}/getSuperUsers`,
    {
      params,
    },
  );
  return response.data;
};

export const getAdminUser = async userId => {
  try {
    const response = await instanceIdentityAuthed.get(`${endpoint}/getUser`, {
      params: { userId },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAdminRoles = async () => {
  const response = await instanceIdentityAuthed.get(`${endpoint}/roles`);
  return response.data;
};

export const createAdmin = async data => {
  const response = await instanceIdentityAuthed.post(
    `${endpoint}/createAdminUser`,
    data,
  );
  return response.data;
};

export const updateAdmin = async data => {
  const response = await instanceIdentityAuthed.put(endpoint, data);
  return response.data;
};

export const changeAdminPassword = async data => {
  const response = await instanceIdentityAuthed.post(
    `${endpoint}/changePassword`,
    data,
  );
  return response.data;
};

export const updateAdminStatus = async data => {
  const response = await instanceIdentityAuthed.post(
    `${endpoint}/updateUserStatus`,
    data,
  );
  return response.data;
};
