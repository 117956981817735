import { instance } from "../../../api";

export const GET_LAB_SUM = "GET_LAB_SUM";
export const GET_LAB_COUNT = "GET_LAB_COUNT";

const isTimeDifferenceGreaterThanOneDay = (date1, date2) => {
  let diff = Math.abs(date2 - date1);
  let oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  return diff > oneDayInMilliseconds;
};

export const getLabSum =
  (startDate, endDate, setLoadingHandler, setPaymentsType) => dispatch => {
    setLoadingHandler(true);
    instance
      .get(
        `order/api/Order/statistic?from=${new Date(
          startDate,
        ).toISOString()}&to=${new Date(
          endDate,
        ).toISOString()}&Provider=Invivo&ProviderType=Lab`,
      )
      .then(res => {
        if (setPaymentsType) {
          setPaymentsType(res?.data?.paymentWithMethod);
        }

        let prevDateStart;
        let prevDateEnd;

        if (
          isTimeDifferenceGreaterThanOneDay(
            new Date(startDate),
            new Date(endDate),
          )
        ) {
          const dateDiff = new Date(endDate) - new Date(startDate);
          prevDateStart = new Date(startDate - dateDiff);
          prevDateEnd = new Date(endDate - dateDiff - 1000);
        } else {
          prevDateStart = new Date(startDate);
          prevDateStart.setDate(prevDateStart.getDate() - 7);

          prevDateEnd = new Date(startDate - 1000);
          prevDateEnd.setDate(prevDateEnd.getDate() - 6);
        }

        instance
          .get(
            `order/api/Order/statistic?from=${new Date(
              prevDateStart,
            ).toISOString()}&to=${new Date(
              prevDateEnd,
            ).toISOString()}&Provider=Invivo&ProviderType=Lab`,
          )
          .then(prevRes =>
            dispatch({
              type: GET_LAB_SUM,
              payload: { current: res.data, prev: prevRes.data },
            }),
          )
          .finally(() => setLoadingHandler(false));
      })
      .catch(() => setLoadingHandler(false));
  };

export const getLabCount =
  (startDate, endDate, statuses, setLoadingHandler, setState) => dispatch => {
    setLoadingHandler(true);
    let stat = statuses.map(el => `&Statuses=${el}`).join("");
    instance
      .get(
        `laboratory/api/Orders/statistic?From=${startDate.toISOString()}&To=${endDate.toISOString()}&Provider=Invivo${stat}`,
      )
      .then(res => {
        let prevDateStart;
        let prevDateEnd;

        if (
          isTimeDifferenceGreaterThanOneDay(
            new Date(startDate),
            new Date(endDate),
          )
        ) {
          const dateDiff = new Date(endDate) - new Date(startDate);
          prevDateStart = new Date(startDate - dateDiff);
          prevDateEnd = new Date(endDate - dateDiff - 1000);
        } else {
          prevDateStart = new Date(startDate);
          prevDateStart.setDate(prevDateStart.getDate() - 7);

          prevDateEnd = new Date(startDate - 1000);
          prevDateEnd.setDate(prevDateEnd.getDate() - 6);
        }

        instance
          .get(
            `laboratory/api/Orders/statistic?From=${new Date(
              prevDateStart,
            ).toISOString()}&To=${new Date(
              prevDateEnd,
            ).toISOString()}&Provider=Invivo&Statuses=3&Statuses=4&Statuses=5&Statuses=6`,
          )
          .then(prevRes => {
            const createdData = { current: res.data, prev: prevRes.data };
            if (setState) {
              setState(createdData);
            } else {
              dispatch({ type: GET_LAB_COUNT, payload: createdData });
            }
          })
          .finally(() => setLoadingHandler(false));
      })
      .catch(() => setLoadingHandler(false));
  };

export const getUsersByIsActive =
  (startDate, endDate, isActive, setLoadingHandler, setState) => () => {
    setLoadingHandler(true);

    instance
      .get(
        `client/api/Profile/statistic?From=${new Date(
          startDate,
        ).toISOString()}&To=${new Date(
          endDate,
        ).toISOString()}&IsActive=${isActive}&CountryCode=KZ`,
      )
      .then(res => {
        let prevDateStart;
        let prevDateEnd;

        if (
          isTimeDifferenceGreaterThanOneDay(
            new Date(startDate),
            new Date(endDate),
          )
        ) {
          const dateDiff = new Date(endDate) - new Date(startDate);
          prevDateStart = new Date(startDate - dateDiff);
          prevDateEnd = new Date(endDate - dateDiff - 1000);
        } else {
          prevDateStart = new Date(startDate);
          prevDateStart.setDate(prevDateStart.getDate() - 7);

          prevDateEnd = new Date(startDate - 1000);
          prevDateEnd.setDate(prevDateEnd.getDate() - 6);
        }

        instance
          .get(
            `client/api/Profile/statistic?From=${new Date(
              prevDateStart,
            ).toISOString()}&To=${new Date(
              prevDateEnd,
            ).toISOString()}&IsActive=${isActive}&CountryCode=KZ`,
          )
          .then(prevRes => {
            const createdData = { current: res.data, prev: prevRes.data };
            setState(createdData);
          })
          .finally(() => setLoadingHandler(false));
      })
      .catch(() => setLoadingHandler(false));
  };

export const getCheckForStatistics = (
  setState,
  setLoadingHandler,
  startDate,
  endDate,
) => {
  setLoadingHandler(true);
  instance
    .get(
      `payment/api/QomekPay/statistic?from=${new Date(
        startDate,
      ).toISOString()}&to=${new Date(endDate).toISOString()}`,
      {
        headers: {
          "api-version": 1,
        },
      },
    )
    .then(res => {
      let prevDateStart;
      let prevDateEnd;

      if (
        isTimeDifferenceGreaterThanOneDay(
          new Date(startDate),
          new Date(endDate),
        )
      ) {
        const dateDiff = new Date(endDate) - new Date(startDate);
        prevDateStart = new Date(startDate - dateDiff);
        prevDateEnd = new Date(endDate - dateDiff - 1000);
      } else {
        prevDateStart = new Date(startDate);
        prevDateStart.setDate(prevDateStart.getDate() - 7);
        prevDateEnd = new Date(startDate - 1000);
        prevDateEnd.setDate(prevDateEnd.getDate() - 6);
      }

      instance
        .get(
          `payment/api/QomekPay/statistic?from=${new Date(
            prevDateStart,
          ).toISOString()}&to=${new Date(prevDateEnd).toISOString()}`,
        )
        .then(prevRes => {
          const check = {
            max: {
              current: res.data?.maxPrice || 0,
              prev: prevRes.data?.maxPrice || 0,
            },
            middle: {
              current: res.data?.middlePrice || 0,
              prev: prevRes.data?.middlePrice || 0,
            },
          };

          setState(check);
        })
        .finally(() => setLoadingHandler(false));
    })
    .catch(() => {
      setLoadingHandler(false);
    });
};

export const getOrdersForStatisticsMainView =
  (setState, setLoadingHandler, startDate, endDate) => () => {
    setLoadingHandler(true);

    function getDateDiff(date1, date2) {
      const d1 = new Date(date1);
      const d2 = new Date(date2);
      const diffMs = d2.getTime() - d1.getTime();
      const msPerDay = 1000 * 60 * 60 * 24;
      const diffDays = Math.floor(diffMs / msPerDay);
      const diffMonths =
        d2.getMonth() +
        12 * d2.getFullYear() -
        (d1.getMonth() + 12 * d1.getFullYear());
      const diffYears = d2.getFullYear() - d1.getFullYear();
      return { days: diffDays, months: diffMonths, years: diffYears };
    }

    let days = getDateDiff(startDate, endDate);
    instance
      .get(
        `payment/api/QomekPay/statistic?from=${new Date(
          endDate,
        ).toISOString()}&intervalDay=${days.days > 1 ? days.days : 7}`,
        {
          headers: {
            "api-version": 1,
          },
        },
      )
      .then(res => {
        setState(res?.data);
      })
      .finally(() => {
        setLoadingHandler(false);
      });
  };

export const getReferralStatistic = (startDate, endDate, setData) => {
  let diff = new Date(endDate).getTime() - new Date(startDate).getTime();
  instance
    .get(
      `clinic/api/Referral/statistic?DateStart=${new Date(
        new Date(startDate).getTime() - diff,
      ).toISOString()}&DateEnd=${new Date(startDate).toISOString()}`,
    )
    .then(res => {
      setData(prev => ({
        ...prev,
        prev: res?.data,
      }));
    });

  instance
    .get(
      `clinic/api/Referral/statistic?DateStart=${new Date(
        startDate,
      ).toISOString()}&DateEnd=${new Date(endDate).toISOString()}`,
    )
    .then(res => {
      setData(prev => ({
        ...prev,
        current: res?.data,
      }));
    });
};
