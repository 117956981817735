import { instance } from "../../../api";
import { requestMessageHandler } from "../../utils/utils";

export const GET_FAQ_DATA = "GET_FAQ_DATA";
export const CLEAR_FAQ_DATA = "CLEAR_FAQ_DATA";
export const GET_FAQ_DATA_BY_ID = "GET_FAQ_DATA_BY_ID";
export const CLEAR_FAQ_DATA_BY_ID = "CLEAR_FAQ_DATA_BY_ID";

export const getFaqData = (setLoadingHandler, params, lang) => dispatch => {
  setLoadingHandler && setLoadingHandler(true);
  instance
    .get(`/content/api/AnswerAndQuestions/getPaging?${params}`, {
      headers: { Language: lang || "ru" },
    })
    .then(res => dispatch({ type: GET_FAQ_DATA, payload: res.data }))
    .finally(() => setLoadingHandler && setLoadingHandler(false));
};
export const clearFaqData = () => dispatch => {
  dispatch({ type: GET_FAQ_DATA });
};

export const getFaqDataById = (id, setLoadingHandler) => dispatch => {
  setLoadingHandler(true);
  instance
    .get(`/content/api/AnswerAndQuestions/getById?id=${id}`)
    .then(res => dispatch({ type: GET_FAQ_DATA_BY_ID, payload: res.data }))
    .finally(() => setLoadingHandler(false));
};
export const clearFaqById = () => dispatch => {
  dispatch({ type: CLEAR_FAQ_DATA_BY_ID });
};

export const postFaqEdit = (data, navigateHandler, enqueueSnackbar) => () => {
  instance
    .post(`/content/api/AnswerAndQuestions/edit`, data)
    .then(() => {
      navigateHandler();
      enqueueSnackbar("Контент отредактирован", { variant: "success" });
    })
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const postFaqCreate =
  (data, navigateHandler, setLoadingHandler, enqueueSnackbar) => () => {
    setLoadingHandler(true);
    for (let i = 0; i < data.length; i++) {
      instance
        .post(`/content/api/AnswerAndQuestions/create`, data[i])
        .then(() => {
          if (i === data.length - 1) {
            navigateHandler();
            enqueueSnackbar("Контент создан", { variant: "success" });
          }
        })
        .catch(e => requestMessageHandler(enqueueSnackbar, e))
        .finally(() => i === data.length - 1 && setLoadingHandler(false));
    }
  };

export const deleteFaqById = (id, getData, enqueueSnackbar) => () => {
  instance
    .delete(`/content/api/AnswerAndQuestions/delete?id=${id}`)
    .then(() => {
      getData(false);
      enqueueSnackbar("Контент удален", { variant: "success" });
    })
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};
