import axios from "axios";
import { BASE_URL, IDENTITY_URL } from "../helpers/enviroments";
import { capitalizeFirstLetter } from "../helpers/utils";
import { LS_TOKEN } from "../services/identity.service";

export const apiVersion2Header = { "api-version": "2.0" };
export const apiVersion3Header = { "api-version": "3.0" };

export const instanceIdentity = axios.create({
  baseURL: IDENTITY_URL,
  headers: { "content-type": "application/x-www-form-urlencoded" },
});

export const instanceIdentityAuthed = axios.create({
  baseURL: IDENTITY_URL,
});

export const instanceYandexGeo = axios.create({
  baseURL: "https://geocode-maps.yandex.ru",
  headers: { "content-type": "application/x-www-form-urlencoded" },
});

export let instance = axios.create({
  baseURL: `${BASE_URL}`,
});

instance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(LS_TOKEN)}`;

  if (config.data?.sortBy) {
    config.data.sortBy = capitalizeFirstLetter(config.data.sortBy);
  } else if (config.params?.sortBy) {
    config.params.sortBy = capitalizeFirstLetter(config.params.sortBy);
  }

  return config;
});

instanceIdentityAuthed.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(LS_TOKEN)}`;
  return config;
});
