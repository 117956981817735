import { getIcon } from "../file/file.api";
import { instance } from "../index";

const endpoint = "laboratory/api/Laboratory";
const errorEndpoint = "laboratory/api";

export const getLabImages = async lab => {
  if (lab) {
    let icon = lab.icon;
    let iconSmall = lab.iconSmall;

    if (lab.icon) {
      try {
        icon = await getIcon(lab.icon);
      } catch (e) {
        icon = lab.icon;
      }
    }
    if (lab.iconSmall) {
      try {
        iconSmall = await getIcon(lab.iconSmall);
      } catch (e) {
        iconSmall = lab.iconSmall;
      }
    }

    return { ...lab, icon, iconSmall };
  } else {
    return lab;
  }
};

export const getLaboratories = async params => {
  const response = await instance.get(endpoint, { params });
  return response.data;
};

export const getLaboratoriesWithImages = async params => {
  const labsData = await getLaboratories(params);
  const labsWithImages = await Promise.all(
    labsData.results.map(async lab => {
      return await getLabImages(lab);
    }),
  );

  return { ...labsData, results: labsWithImages };
};

export const createLaboratory = async data => {
  const response = await instance.post(endpoint, data);
  return response.data;
};
export const editLaboratory = async data => {
  const response = await instance.put(endpoint, data);
  return response.data;
};

//localizations
export const getLaboratoryLocalization = async params => {
  const response = await instance.get(`${endpoint}/localization`, { params });
  return response.data;
};
export const createLaboratoryLocalization = async data => {
  const response = await instance.post(`${endpoint}/localization`, data);
  return response.data;
};
export const editLaboratoryLocalization = async data => {
  const response = await instance.put(`${endpoint}/localization`, data);
  return response.data;
};

//connections
export const getLaboratoryConnection = async params => {
  const response = await instance.get(`${endpoint}/connection`, { params });
  return response.data;
};
export const createLaboratoryConnection = async data => {
  const response = await instance.post(`${endpoint}/connection`, data);
  return response.data;
};
export const editLaboratoryConnection = async data => {
  const response = await instance.put(`${endpoint}/connection`, data);
  return response.data;
};

//notifications
export const getLaboratoryNotification = async params => {
  const response = await instance.get(`${endpoint}/notification`, { params });
  return response.data;
};
export const createLaboratoryNotification = async data => {
  const response = await instance.post(`${endpoint}/notification`, data);
  return response.data;
};
export const editLaboratoryNotification = async data => {
  const response = await instance.put(`${endpoint}/notification`, data);
  return response.data;
};

export const getOrderErrors = async params => {
  const response = await instance.get(
    `${errorEndpoint}/LaboratoryErrorProcess`,
    {
      params,
    },
  );

  return response.data;
};
