import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { currentProfileState } from "../store/client.states";

const AuthGuard = ({ children }) => {
  const currentProfile = useRecoilValue(currentProfileState);

  const { pathname } = useLocation();

  return currentProfile ? (
    children
  ) : (
    <Navigate replace to="/login" state={{ from: pathname }} />
  );
};

export default AuthGuard;
