import { instanceIdentityAuthed } from "../index";

const endpoint = "api/UserClaims";

export const getUserClaims = async UserId => {
  const response = await instanceIdentityAuthed.get(`${endpoint}/byUserId`, {
    params: { UserId },
  });
  return response.data;
};

export const addUserClaim = async data => {
  const response = await instanceIdentityAuthed.post(
    `${endpoint}/addClaim`,
    data,
  );
  return response.data;
};

export const addUserClaims = async data => {
  const response = await instanceIdentityAuthed.post(
    `${endpoint}/addClaims`,
    data,
  );
  return response.data;
};

export const updateUserClaims = async data => {
  const response = await instanceIdentityAuthed.post(
    `${endpoint}/updateClaims`,
    data,
  );
  return response.data;
};

export const deleteUserClaim = async data => {
  const response = await instanceIdentityAuthed.delete(
    `${endpoint}/deleteClaim`,
    { data },
  );
  return response.data;
};
