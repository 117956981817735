import { instanceIdentity } from "../index";
import {
  CLIENT_ID,
  CLIENT_SECRET,
  GRANT_TYPE,
  GRANT_TYPE_REFRESH,
  LS_REFRESH_TOKEN,
  LS_TOKEN,
} from "../../services/identity.service";

const endpoint = "connect";

//get token types = 'refresh' | 'login'
export const getToken = async (type, values) => {
  const data = new URLSearchParams();
  data.append("client_id", CLIENT_ID);
  data.append("client_secret", CLIENT_SECRET);
  if (type === "refresh") {
    data.append("grant_type", GRANT_TYPE_REFRESH);
    data.append("refresh_token", localStorage.getItem(LS_REFRESH_TOKEN));
  } else {
    data.append("username", values?.username || "");
    data.append("password", values?.password || "");
    data.append("grant_type", GRANT_TYPE);
  }

  const response = await instanceIdentity.post(`${endpoint}/token`, data);

  localStorage.setItem(LS_TOKEN, response.data.access_token);
  localStorage.setItem(LS_REFRESH_TOKEN, response.data.refresh_token);

  return response.data;
};
