const { instance } = require("api");

const serviceEndpoint = "price/api/ServiceType";

export const findServiceTypes = async () => {
  const response = await instance.get(`${serviceEndpoint}/find`);
  return response.data;
};

export const addServiceType = async data => {
  const response = await instance.post(`${serviceEndpoint}/add`, data);
  return response.data;
};

export const updateServiceType = async data => {
  const response = await instance.put(`${serviceEndpoint}/update`, data);
  return response.data;
};

export const deleteServiceType = async Id => {
  const response = await instance.delete(`${serviceEndpoint}/delete`, {
    params: { Id },
  });
  return response.data;
};
