import { getClinicById } from "api/clinic/clinic.api";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLocation, useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";
import { getProfile } from "../api/client/profile.api";
import { getAllCountries } from "../api/geo/countries.api";
import { getAllRegions } from "../api/geo/regions.api";
import { getLaboratories } from "../api/laboratory/laboratory.api";
import { getPriceGroups } from "../api/price/group.api";
import { findServiceTypes } from "../api/price/service-type.api";
import Loading from "../components/MatxLoading";
import useCurrentProfileData from "../hooks/useCurrentProfileData";
import useGetAuthedData from "../hooks/useGetAuthedData";
import useLogout from "../hooks/useLogout";
import { getLocalStorageCredentials } from "../services/identity.service";
import {
  coordinatesState,
  countriesState,
  regionsState,
} from "../store/geo.states";
import { laboratoriesState } from "../store/laboratory.states";
import { priceGroupsState, serviceTypesState } from "../store/price.states";
import "../style.scss";
import { WebSocketContext } from "./WebSocket";

const PrefetchProvider = ({ children }) => {
  const setRegions = useSetRecoilState(regionsState);
  const setCountries = useSetRecoilState(countriesState);
  const setPriceGroups = useSetRecoilState(priceGroupsState);
  const setLaboratories = useSetRecoilState(laboratoriesState);
  const setServiceTypes = useSetRecoilState(serviceTypesState);
  const setCoordinates = useSetRecoilState(coordinatesState);

  const logout = useLogout();
  const getAuthedData = useGetAuthedData();
  const { enqueueSnackbar } = useSnackbar();
  const getProfileData = useCurrentProfileData();
  const navigate = useNavigate();
  const location = useLocation();

  const { messageFromNotificationWebSocket } =
    React.useContext(WebSocketContext);

  React.useEffect(() => {
    if (messageFromNotificationWebSocket.target === "create_new_consultation") {
      getClinicById(
        messageFromNotificationWebSocket.arguments[0].ClinicId,
      ).then(clinic => {
        enqueueSnackbar(`Новая запись в клинику ${clinic.value.name}!`, {
          variant: "info",
          onClick: () => {
            navigate(
              `/consultations/offline/details/${messageFromNotificationWebSocket.arguments[0].consultationId}`,
            );
          },
          onClose: () => {
            location.pathname.includes("/consultations") &&
              window.location.reload();
          },
        });
      });
    }
  }, [messageFromNotificationWebSocket]);

  const [loading, setLoading] = useState(true);

  const handleAuth = async () => {
    const credentials = getLocalStorageCredentials();

    if (!credentials?.refreshToken || !credentials?.token) {
      logout();
      enqueueSnackbar("Нет доступа", { variant: "error" });
    } else {
      const profile = await getProfile();

      if (profile) {
        await Promise.all([getAuthedData(), getProfileData(profile)]);
      } else {
        enqueueSnackbar("Профиль поврежден", { variant: "error" });
        logout();
      }
    }
  };

  const handlePrefetchData = async () => {
    const [countries, regions, priceGroups, serviceTypes, laboratories] =
      await Promise.allSettled([
        getAllCountries(),
        getAllRegions(),
        getPriceGroups(),
        findServiceTypes(),
        getLaboratories({
          Page: 1,
          PageSize: 20,
          SortBy: "name",
          SortType: 0,
        }),
        handleAuth(),
      ]);

    if (countries.status === "fulfilled") {
      setCountries(countries.value);
    }
    if (regions.status === "fulfilled") {
      const regionsByCountry = {};
      const regionsValue = regions.value;

      for (let i = 0; i < regionsValue.length; i++) {
        const currentRegion = regionsValue[i];
        //by country
        if (regionsByCountry[currentRegion.countryCode])
          regionsByCountry[currentRegion.countryCode].push(currentRegion);
        else regionsByCountry[currentRegion.countryCode] = [currentRegion];
        //all
        if (regionsByCountry.all) regionsByCountry.all.push(currentRegion);
        else regionsByCountry.all = [currentRegion];
      }

      setRegions(regionsByCountry);
    }
    if (priceGroups.status === "fulfilled") {
      setPriceGroups(priceGroups.value.value);
    }
    if (serviceTypes.status === "fulfilled") {
      setServiceTypes(serviceTypes.value.value);
    }
    if (laboratories.status === "fulfilled") {
      setLaboratories(laboratories.value.results);
    }
  };

  useEffect(() => {
    handlePrefetchData().finally(() => setLoading(false));
    // getUserGeolocation().then(setCoordinates); // QS-2922
  }, []);

  return loading ? <Loading /> : children;
};

export default PrefetchProvider;
