import { instance } from "api";
import { createPrevPeriodByCurrentPeriod } from "pages/Dashboard/helpers/utils";

const serviceEndpoint = "client/api/Profile";

export const getProfile = async () => {
  const response = await instance.get(serviceEndpoint);

  if (response.data) {
    return response.data;
  } else {
    return true;
  }
};

export const findUsers = async params => {
  const response = await instance.get(`${serviceEndpoint}/find`, {
    params,
  });

  return response.data;
};

export const findUserByUserId = async userId => {
  const response = await instance.get(`${serviceEndpoint}/byUserId`, {
    params: { userId },
  });
  return response.data;
};

export const getUserById = async id => {
  const response = await instance.get(`${serviceEndpoint}/byId`, {
    params: { id },
  });
  return response.data;
};

export const findDoctor = async params => {
  const response = await instance.get(`${serviceEndpoint}/findDoctor`, {
    params,
  });
  return response.data;
};

export const updateUserDescription = async data => {
  const response = await instance.put(
    `${serviceEndpoint}/updateUserDescription`,
    data,
  );
  return response.data;
};

export const createUserForAdmin = async data => {
  const response = await instance.post(
    `${serviceEndpoint}/createUserForAdmin`,
    data,
  );
  return response.data;
};

export const createVirtualUser = async data => {
  const response = await instance.post(
    `${serviceEndpoint}/createVirtualUser`,
    data,
  );
  return response.data;
};

export const updateUserForAdmin = async data => {
  const response = await instance.put(
    `${serviceEndpoint}/updateProfileForAdmin`,
    data,
  );
  return response.data;
};

export const getUsersStatistics = async params => {
  const prevPeriod = createPrevPeriodByCurrentPeriod(params.From, params.To);
  const prevPeriodParams = {
    ...params,
    From: prevPeriod.startDate,
    To: prevPeriod.endDate,
  };

  const [prevPeriodData, currentPeriodData] = await Promise.all([
    instance.get(`${serviceEndpoint}/statistic`, { params: prevPeriodParams }),
    instance.get(`${serviceEndpoint}/statistic`, { params }),
  ]);
  return { prev: prevPeriodData.data, current: currentPeriodData.data };
};

export const updateUserWriteOffAmount = async data => {
  const response = await instance.put(
    `${serviceEndpoint}/updateUserWriteOffAmount`,
    data,
  );

  return response.data;
};
