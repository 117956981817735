import { instance } from "api";

const serviceEndpoint = "clinic/api/Clinics";

// Поиск клиник по параметрам
export const findClinics = async params => {
  const response = await instance.get(`${serviceEndpoint}/find`, { params });
  return response.data;
};
export const getClinicById = async clinicId => {
  const response = await instance.get(
    `${serviceEndpoint}/byClinicId?Id=${clinicId}`,
  );
  return response.data;
};
export const createClinic = async data => {
  const response = await instance.post(`${serviceEndpoint}/add`, data);
  return response.data;
};
export const updateClinic = async data => {
  const response = await instance.put(`${serviceEndpoint}/update`, data);
  return response.data;
};
export const deleteClinic = async id => {
  const response = await instance.delete(`${serviceEndpoint}/delete`, {
    params: { id },
  });
  return response.data;
};
export const importClinicFile = async data => {
  const response = await instance.post(`${serviceEndpoint}/importFile`, data);
  return response.data;
};
export const getClinicCard = async clinicId => {
  const response = await instance.get(`${serviceEndpoint}/getCard`, {
    params: { clinicId },
  });

  return response.data;
};

export const importDoctors = async data => {
  const response = await instance.post(
    `${serviceEndpoint}/importDoctors`,
    data,
  );

  return response.data;
};
