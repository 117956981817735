import { instance } from "../../../api";

export const GET_ALL_LABS = "GET_ALL_LABS";
export const CLEAR_LABS = "CLEAR_LABS";
export const GET_LAB_BY_ID = "GET_LAB_BY_ID";
export const CLEAR_LAB_BY_ID = "CLEAR_LAB_BY_ID";
export const GET_LAB_TRANSLATES_BY_ID = "GET_LAB_TRANSLATES_BY_ID";
export const CLEAR_LAB_TRANSLATES_BY_ID = "CLEAR_LAB_TRANSLATES_BY_ID";

export const findLabs = async filters => {
  const response = await instance.post("/laboratory/api/Labs/find", filters);
  return response.data;
};

export const getAllLabs = (setLoadingHandler, byCode, setLabs) => {
  if (setLoadingHandler) {
    setLoadingHandler(true);
  }
  if (byCode) {
    instance
      .get(`/laboratory/api/Labs/byCode?code=${byCode}`)
      .then(res => setLabs(res.data))
      .finally(() => {
        if (setLoadingHandler) {
          setLoadingHandler(false);
        }
      });
  } else {
    instance
      .post(`/laboratory/api/Labs/find`)
      .then(res => setLabs(res.data.results))
      .finally(() => {
        if (setLoadingHandler) {
          setLoadingHandler(false);
        }
      });
  }
};
export const clearLabs = () => dispatch => {
  dispatch({ type: CLEAR_LABS });
};

export const getLabById = (id, setLoadingHandler, setLab) => {
  setLoadingHandler(true);
  instance
    .get(`/laboratory/api/Labs/getById?Id=${id}`)
    .then(res => setLab(res.data))
    .finally(() => setLoadingHandler(false));
};

export const getLaboratories = async filters => {
  const response = await instance.get(
    `/laboratory/api/Laboratory?${new URLSearchParams(filters)}`,
  );
  return response.data;
};

export const clearLabById = () => dispatch => {
  dispatch({ type: CLEAR_LAB_BY_ID });
};

export const getLabTranslatesById = (id, setLoadingHandler, setData) => {
  instance
    .get(`/laboratory/api/LabsLocalization/byMainId?MainId=${id}`)
    .then(res => setData(res?.data));
};

export const clearLabTranslatesById = () => dispatch => {
  dispatch({ type: CLEAR_LAB_TRANSLATES_BY_ID });
};

export const createLaboratory = (data, enq, navigate, setLoading) => {
  return instance
    .post(`/laboratory/api/Labs/addLabs`, data)
    .then(res => {
      enq("Лаборатория создана!", { variant: "success" });
      return res?.data.value?.id;
    })
    .catch(() => null)
    .finally(() => {
      setLoading(false);
      navigate("/laboratories");
    });
};

export const UpdateLaboratory = (data, enq, navigate, setLoading) => {
  instance
    .put(`/laboratory/api/Labs/updateLabs`, data)
    .then(() => {
      enq("Лаборатория изменена!", { variant: "success" });
    })
    .catch(() => null)
    .finally(() => {
      setLoading(false);
      navigate("/laboratories");
    });
};

export const createLabTranslates = data => {
  instance
    .post(`/laboratory/api/LabsLocalization/addLabsLocalization`, data)
    .then(() => null);
};

export const updateLab = data => {
  return instance.put(`/laboratory/api/Labs/updateLabs`, data);
};

export const getResultsFromLIS = async OrderId => {
  const response = await instance.get(
    `/laboratory/api/Orders/getResultsFromLIS?OrderId=${OrderId}`,
  );
  return response.data;
};

export const getOrderResultsFile = async orderId => {
  const response = await instance.get(
    `/laboratory/api/ResultResearch/result/User/order/${orderId}/file`,
  );
  return response.data;
};
export const sendNotificationPayOrder = async orderId => {
  const response = await instance.post(
    `/laboratory/api/Orders/notificationPayOrder?orderId=${orderId}`,
  );
  return response.data;
};

export const updateLabTranslatesByID = data => {
  instance
    .put(`/laboratory/api/LabsLocalization/updateLabsLocalization`, data)
    .then(() => null);
};

export const deleteLab = (data, enq, onSuccess, closeModal) => {
  instance
    .delete("/laboratory/api/Labs/deleteLaboratories", { data: data })
    .then(() => {
      onSuccess();
      enq("Лаборатория удалена!", { variant: "success" });
    })
    .finally(() => {
      closeModal(null);
      return null;
    });
};

export const deleteLabTranslates = data => () => {
  instance
    .delete(`/laboratory/api/LabsLocalization/deleteLabsLocalization`, {
      data: data,
    })
    .then(() => null);
};

export const labGetRegions = setRegions => {
  instance.get(`/geo/api/Regions`).then(res => setRegions(res.data));
};

export const labGetCountries = setCountries => {
  instance.get(`/geo/api/Countries`).then(res => setCountries(res.data));
};
