import { getProfile } from "api/client/profile.api";
import { getToken } from "api/identity/authorization.api";
import { useSnackbar } from "notistack";
import useLogout from "./useLogout";
import useCurrentProfileData from "./useCurrentProfileData";
import { useRecoilValue } from "recoil";
import { currentProfileState } from "store/client.states";
import useGetAuthedData from "./useGetAuthedData";

const useAuth = () => {
  const logout = useLogout();
  const getAuthedData = useGetAuthedData();
  const { enqueueSnackbar } = useSnackbar();
  const getProfileData = useCurrentProfileData();

  const currentProfile = useRecoilValue(currentProfileState);

  const login = async values => {
    try {
      await getToken("login", values);

      const profile = await getProfile();

      if (profile) {
        await Promise.all([getAuthedData(), getProfileData(profile)]);

        enqueueSnackbar("Вы вошли в аккаунт", { variant: "success" });
      } else {
        enqueueSnackbar("Профиль поврежден", { variant: "warning" });
      }
    } catch (e) {
      const status = e?.response?.data?.status;
      const message =
        status === 401 || status === 403
          ? "Неверное имя пользователя или пароль"
          : typeof e === "string"
            ? e
            : "Ошибка сервера";

      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const refresh = async () => {
    try {
      await getToken("refresh");

      if (!currentProfile) {
        const profile = await getProfile();

        if (profile) {
          await getProfileData(profile);

          enqueueSnackbar("Вы вошли в аккаунт", { variant: "success" });
        } else {
          enqueueSnackbar("Профиль поврежден", { variant: "warning" });
          logout();
        }
      }
    } catch (e) {
      enqueueSnackbar("Сессия истекла", { variant: "error" });
      logout();
    }
  };

  return { refresh, login };
};

export default useAuth;
