import { instance } from "api";

const serviceEndpoint = "client/api/ProfilePartner";

export const getProfilePartner = async params => {
  const response = await instance.get(`${serviceEndpoint}/getAll`, { params });
  return response.data;
};

export const addProfilePartner = async data => {
  const response = await instance.post(`${serviceEndpoint}/add`, data);
  return response.data;
};

export const updateProfilePartner = async data => {
  const response = await instance.put(`${serviceEndpoint}/update`, data);
  return response.data;
};

export const deleteProfilePartner = async id => {
  const response = await instance.delete(`${serviceEndpoint}/delete`, {
    data: { id },
  });
  return response.data;
};
