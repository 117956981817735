import { lazy } from "react";
import AuthGuard from "shared/AuthGuard";
import Redirect from "shared/Redirect";
import { mainRoutes } from "./app/routing";
import Loadable from "./components/Loadable";
import MatxLayout from "./layout/MatxLayout/MatxLayout";
import {
  EnumPermissionsKeys,
  EnumPermissionsTypes,
  handleCreatePermissionCode,
} from "./services/permissions.service";
import ScopeLayout from "./shared/ScopeLayout";

const LoginPage = Loadable(lazy(() => import("./pages/Login")));
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const DashboardOld = Loadable(lazy(() => import("./app/pages/Dashboard")));
const Dashboard = Loadable(lazy(() => import("./pages/Dashboard")));
const NewDashboard = Loadable(
  lazy(() => import("./app/pages/NewDashboard/NewDashboard")),
);
const DashboardMainView = Loadable(
  lazy(() => import("./app/pages/Dashboard/DashboardMainView")),
);

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...mainRoutes,
      {
        path: "/analytics",
        element: (
          <ScopeLayout
            permissionKey={handleCreatePermissionCode(
              EnumPermissionsKeys.reports,
              EnumPermissionsTypes.view,
            )}
          >
            <Dashboard />
          </ScopeLayout>
        ),
      },
      {
        path: "/dashboard",
        element: (
          <ScopeLayout
            permissionKey={handleCreatePermissionCode(
              EnumPermissionsKeys.reports,
              EnumPermissionsTypes.view,
            )}
          >
            <DashboardMainView />
          </ScopeLayout>
        ),
      },
      {
        path: "/newDashboard",
        element: (
          <ScopeLayout
            permissionKey={handleCreatePermissionCode(
              EnumPermissionsKeys.reports,
              EnumPermissionsTypes.view,
            )}
          >
            <NewDashboard />
          </ScopeLayout>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  //dashboard
  {
    path: "/dashboard",
    element: <MatxLayout />,
    children: [
      {
        path: "/dashboard/orders",
        element: <DashboardOld />,
      },
    ],
  },
  { path: "/", element: <Redirect /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
