export const ENV = process.env.REACT_APP_ENV;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_WS_URL = process.env.REACT_APP_BASE_WS_URL;
export const IDENTITY_URL = process.env.REACT_APP_IDENTITY_URL;
export const RECEIPT_URL = process.env.REACT_APP_RECEIPT_URL;
export const QOMEK_URL = process.env.REACT_APP_QOMEK_URL;

export const isEnviromentProd = () => {
  const env = process.env.REACT_APP_ENV;
  return env === "prod";
};
