import { instance } from "api";
import { FILE_TYPE_DOCUMENT } from "app/utils/constant";

export const GET_USERS_BY_PAGE = "GET_USERS_BY_PAGE";
export const CLEAR_USERS_BY_PAGE = "CLEAR_USERS_BY_PAGE";
export const GET_USERS_BY_ID = "GET_USERS_BY_ID";
export const CLEAR_USER_BY_ID = "CLEAR_USER_BY_ID";
export const GET_DOC_BY_USERID_AND_DOC_ID = "GET_DOC_BY_USERID_AND_DOC_ID";
export const CLEAR_DOC_BY_USERID_AND_DOC_ID = "CLEAR_DOC_BY_USERID_AND_DOC_ID";

export const getUserByUserIdSetState = (userId, setState) => () => {
  instance
    .get(`/client/api/Profile/byUserId?userId=${userId}`)
    .then(res => setState(prevState => [...prevState, res.data.value]));
};

export const getDocByUserIdAndDocId =
  (userId, docInfo, setLoadingHandler) => dispatch => {
    setLoadingHandler(true);
    //fileapi/api/FileApi/pagin?UserId=
    instance
      .get(`fileapi/api/FileApi?id=${docInfo.id}`, {
        responseType: "blob",
      })
      .then(res => {
        const data = { info: docInfo, data: URL.createObjectURL(res.data) };
        dispatch({ type: GET_DOC_BY_USERID_AND_DOC_ID, payload: data });
      })
      .finally(() => setLoadingHandler(false));
  };
export const clearDocByUserIdAndDocId = () => dispatch => {
  dispatch({ type: CLEAR_DOC_BY_USERID_AND_DOC_ID });
};

export const getDocsByUserId = (userId, setLoadingHandler) => dispatch => {
  setLoadingHandler(true);
  instance
    .get(
      `fileapi/api/FileApi/pagin?UserId=${userId}&FileType=${FILE_TYPE_DOCUMENT}`,
    )
    .then(res => {
      clearDocByUserIdAndDocId();
      if (res?.data.value.results.length) {
        for (let i = 0; i < res?.data.value.results.length; i++) {
          dispatch(
            getDocByUserIdAndDocId(
              userId,
              res?.data.value.results[i],
              setLoadingHandler,
            ),
          );
        }
      }
    })
    .catch(error => console.error(error))
    .finally(() => setLoadingHandler(false));
};
export const createApplicationForUser = async (userId, data) => {
  const response = await instance.post(
    `/clinic/api/Applications/forUser?userId=${userId}`,
    data,
  );
  return response.data;
};
export const approveApplication = async data => {
  const response = await instance.post("clinic/api/Applications/approve", data);
  return response.data;
};
export const createPartnerProfile = async data => {
  const response = await instance.post(`/client/api/ProfilePartner/add`, data);
  return response.data;
};
