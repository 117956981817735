import { instance } from "../../../api";
import { requestMessageHandler } from "../../utils/utils";

export const GET_ALL_PRICES = "GET_ALL_PRICES";
export const CLEAR_PRICES = "CLEAR_PRICES";
export const GET_ALL_PRICES_BY_TOP = "GET_ALL_PRICES_BY_TOP";
export const CLEAR_PRICES_BY_TOP = "CLEAR_PRICES_BY_TOP";
export const GET_ALL_SERVICES = "GET_ALL_SERVICES";
export const CLEAR_SERVICES = "CLEAR_SERVICES";
export const GET_ALL_REGIONS = "GET_ALL_REGIONS";
export const GET_ALL_PROVIDERS = "GET_ALL_PROVIDERS";
export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const GET_REGION_BY_CODE = "GET_REGION_BY_CODE";

export const getAllRegions = () => dispatch => {
  instance
    .get(`/geo/api/Regions`)
    .then(res => dispatch({ type: GET_ALL_REGIONS, data: res.data }));
};

export const getRegionByCode = regionCode => dispatch => {
  instance
    .get(`/geo/api/Regions/byCode?code=${regionCode}`)
    .then(res => dispatch({ type: GET_REGION_BY_CODE, data: res.data[0] }));
};

export const getAllProviders = () => dispatch => {
  instance
    .post(`/laboratory/api/Labs/find`)
    .then(res => dispatch({ type: GET_ALL_PROVIDERS, data: res.data.results }));
};

export const getAllPrices = (setLoadingHandler, params) => dispatch => {
  setLoadingHandler(true);
  instance
    .get(`/price/api/Price/findWithPaging?${params}`)
    .then(res => dispatch({ type: GET_ALL_PRICES, data: res.data }))
    .finally(() => setLoadingHandler(false));
};
export const getAllPricesNoPaging = (setLoadingHandler, params) => dispatch => {
  setLoadingHandler(true);
  instance
    .get(`/price/api/Price/find?${params}`)
    .then(res => dispatch({ type: GET_ALL_PRICES, data: res.data }))
    .finally(() => setLoadingHandler(false));
};
export const clearPrices = () => dispatch => {
  dispatch({ type: CLEAR_PRICES });
};

export const getAllPricesByTop = (setLoadingHandler, params) => dispatch => {
  setLoadingHandler(true);
  const keyMutatedParams = {
    provider: params.Provider,
    regionCode: params.regionCode,
  };
  instance
    .get(
      `/price/api/Price/byTop?${new URLSearchParams(
        keyMutatedParams,
      )}&code=TopInvivo`,
    )
    .then(res => dispatch({ type: GET_ALL_PRICES_BY_TOP, data: res.data }))
    .finally(() => setLoadingHandler(false));
};

export const getAllServices = (setLoadingHandler, params) => dispatch => {
  setLoadingHandler(true);
  instance
    .get(`/price/api/Service/services?${params}`)
    .then(res => dispatch({ type: GET_ALL_SERVICES, data: res.data }))
    .finally(() => setLoadingHandler(false));
};

export const clearServices = () => dispatch => {
  dispatch({ type: CLEAR_SERVICES });
};

export const getServicesTranslates =
  (setLoadingHandler, ServiceCode, setState) => () => {
    setLoadingHandler(true);
    instance
      .get(`/price/api/Localization?ServiceCode=${ServiceCode}`)
      .then(res => setState(res.data?.results || null))
      .finally(() => setLoadingHandler(false));
  };

export const postCreateService = (data, onSuccess, enqueueSnackbar) => () => {
  instance
    .post(`/price/api/Service/add`, data)
    .then(() => {
      onSuccess();
      enqueueSnackbar("Услуга создана", { variant: "success" });
    })
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const postCreateServiceTranslates = data => () => {
  for (let i = 0; i < data.length; i++) {
    instance
      .post(`/price/api/Localization/add`, data[i])
      .then(() => null)
      .catch(() => null);
  }
};

export const postUpdateService = (data, onSuccess, enqueueSnackbar) => () => {
  instance
    .put(`/price/api/Service/update`, data)
    .then(() => {
      onSuccess();
      enqueueSnackbar("Услуга отредактирована", { variant: "success" });
    })
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const postUpdateServiceTranslates = data => dispatch => {
  for (let i = 0; i < data.length; i++) {
    instance
      .put(`/price/api/Localization/update`, data[i])
      .then(() => null)
      .catch(() => dispatch(postCreateServiceTranslates([data[i]])));
  }
};

export const deleteService = (id, getData, enqueueSnackbar) => () => {
  instance
    .delete(`/price/api/Service/delete?Id=${id}`)
    .then(() => {
      getData();
      enqueueSnackbar("Услуга удалена", { variant: "success" });
    })
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const getAllGroups = setLoadingHandler => dispatch => {
  setLoadingHandler(true);
  instance
    .get(`/price/api/Group`)
    .then(res => dispatch({ type: GET_ALL_GROUPS, data: res.data }))
    .finally(() => setLoadingHandler(false));
};
