import { instance } from "../index";

const serviceEndpoint = "content/api/Userform";

export const getAnswersToFormByFormId = async params => {
  const response = await instance.get(
    `${serviceEndpoint}/CompletedFormsAdmin`,
    {
      params,
    },
  );

  return response?.data?.value;
};
