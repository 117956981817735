export const topBarHeight = 64;
export const navbarHeight = 60;
export const sideNavWidth = 260;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;

//file settings
//file type
export const FILE_TYPE_ICON = "Icon";
export const FILE_TYPE_DOCUMENT = "Document";

//storage type
export const STORAGE_TYPE_DIRECTORY = "Directory";
export const STORAGE_TYPE_DATABASE = "DataBase";

//regex
export const validOrderIdHexRegex = /^[0-9a-fA-F]{24}$/;
export const uuidPattern =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const nurseStatuses = [
  {
    name: "Неизвестно",
    value: [-1],
  },

  {
    name: "Зарегистрирован",
    value: [0, 1],
  },
  {
    name: "Оплачен",
    value: [2, 3, 4, 5, 12],
  },
  {
    name: "Готово",
    value: [6],
  },
  {
    name: "Отменен",
    value: [7, 8, 11],
  },
  // {
  //     name: 'Удален',
  //     value: [8]
  // },
  {
    name: "Ошибка",
    value: [9],
  },
  {
    name: "В ожидании оплаты",
    value: [10],
  },
];

export const statuses = [
  {
    name: "Неизвестно",
    value: [-1],
  },
  {
    name: "Направлено",
    value: [0],
  },
  {
    name: "Зарегистрирован",
    value: [1],
  },
  {
    name: "Оплачен",
    value: [2, 3, 4, 5, 12],
  },
  {
    name: "Готово",
    value: [6],
  },
  {
    name: "Отменен",
    value: [7, 8, 11],
  },
  // {
  //     name: 'Удален',
  //     value: [8]
  // },
  {
    name: "Ошибка",
    value: [9],
  },
  {
    name: "В ожидании оплаты",
    value: [10],
  },
];

export const applicationsStatuses = [
  {
    name: "Одобрена",
    value: 6,
  },
  {
    name: "Создано",
    value: 0,
  },
  {
    name: "Отклонена",
    value: 9,
  },
  {
    name: "Отозвана",
    value: 7,
  },
  {
    name: "Частичное заполнение",
    value: 1,
  },
];

export const ticketStatuses = [
  {
    name: "Открыто",
    value: 0,
  },
  {
    name: "Закрыто",
    value: 1,
  },
  {
    name: "В процессе",
    value: 2,
  },
  {
    name: "В ожидании",
    value: 3,
  },
];

export const scopeTypes = [
  {
    name: "Пациент",
    value: "client",
  },
  {
    name: "Доктор",
    value: "doctor",
  },
];

export const providerTypes = ["Lab", "Clinic"];
export const languagesLettered = [
  { name: "Русский", value: "ru" },
  { name: "Казахский", value: "kz" },
  { name: "Английский", value: "en" },
];
