import { BASE_WS_URL } from "helpers/enviroments";

class NotificationWebSocket {
  constructor() {
    this.messageHandlers = [];
    this.pingInterval = null;
  }

  connect() {
    console.info("Попытка установить соединение WebSocket...");
    this.socket = new WebSocket(`${BASE_WS_URL}/notification/rtc`);
    this.socket.onopen = this.onOpen.bind(this);
    this.socket.onmessage = this.onMessage.bind(this);
    this.socket.onerror = this.onError.bind(this);
    this.socket.onclose = this.onClose.bind(this);
  }

  onOpen(event) {
    console.info("Соединение WebSocket установлено", event);
    const initialMessage =
      JSON.stringify({
        protocol: "json",
        version: 1,
      }) + "\u001e";
    this.sendMessage(initialMessage);

    // Затем отправьте следующее сообщение, если требуется
    setTimeout(() => {
      const message =
        JSON.stringify({
          type: 1,
          target: "Autojoin",
          arguments: [
            "6cbe492c-eb83-4d03-9e4f-5b0fa8618dca",
            "2i1oiewo",
            "3",
            "Admin",
          ],
        }) + "\u001e";
      this.sendMessage(message);
    }, 1000);

    // Начинаем пинговать сервер каждые 30 секунд
    this.pingInterval = setInterval(() => {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: "ping", RS: "\u001e" }));
      }
    }, 30000);
  }

  onMessage(event) {
    try {
      const parsedData = JSON.parse(event.data.replace(/[\x1E]/g, "").trim());
      if (parsedData.type === 6) {
      } else if (parsedData.error) {
        console.error("Ошибка WebSocket:", parsedData.error);
      } else {
        this.messageHandlers.forEach(handler => handler(parsedData));
      }
    } catch (error) {
      console.error("Ошибка разбора JSON:", error);
    }
  }

  onError(error) {
    console.error("Ошибка WebSocket:", error);
  }

  onClose(event) {
    console.info("Соединение WebSocket закрыто", event);
    console.info(
      `Код закрытия: ${event.code}, Причина закрытия: ${event.reason}`,
    );

    // Логируем, какая сторона закрыла соединение
    if (event.wasClean) {
      console.info("Соединение закрыто чисто.");
    } else {
      console.info("Соединение закрыто с ошибкой.");
    }

    clearInterval(this.pingInterval);
    if (event.code !== 1000) {
      // Попробуем переподключиться после 5 секунд
      setTimeout(() => {
        console.info("Повторное подключение...");
        this.connect();
      }, 5000);
    }
  }

  close() {
    if (this.socket) {
      clearInterval(this.pingInterval);
      this.socket.close(1000, "Клиент закрыл соединение");
      console.info("Соединение WebSocket закрыто клиентом");
    }
  }

  sendMessage(message) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error("Соединение WebSocket не открыто");
    }
  }

  addMessageHandler(handler) {
    this.messageHandlers.push(handler);
  }

  removeMessageHandler(handler) {
    this.messageHandlers = this.messageHandlers.filter(h => h !== handler);
  }
}

const notificationWebSocket = new NotificationWebSocket();
export default notificationWebSocket;
