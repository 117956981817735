import React from "react";

const QomekLogo = ({ className }) => {
  return (
    <svg
      className={className}
      width="30"
      height="32"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 11.9737C0 5.3608 5.3608 0 11.9737 0H53.0263C59.6392 0 65 5.3608 65 11.9737V53.0263C65 59.6392 59.6392 65 53.0263 65H11.9737C5.3608 65 0 59.6392 0 53.0263V11.9737Z"
        fill="#FD385B"
      />
      <path
        d="M32.6155 50.75C29.8178 50.75 27.2712 50.2118 24.9756 49.1354C22.7159 48.0231 20.7611 46.5521 19.1112 44.7222C17.4971 42.8924 16.2418 40.8293 15.3451 38.533C14.4484 36.2008 14 33.8148 14 31.375C14 28.8275 14.4663 26.4057 15.3989 24.1094C16.3673 21.7772 17.6765 19.7141 19.3264 17.9201C21.0122 16.0903 22.9849 14.6551 25.2446 13.6146C27.5402 12.5382 30.0509 12 32.7769 12C35.5387 12 38.0495 12.5561 40.3092 13.6684C42.6047 14.7807 44.5595 16.2697 46.1736 18.1354C47.7877 20.0012 49.043 22.0822 49.9397 24.3785C50.8364 26.6748 51.2848 29.0428 51.2848 31.4826C51.2848 33.9942 50.8185 36.4161 49.8859 38.7483C48.9534 41.0446 47.6442 43.1076 45.9584 44.9375C44.3085 46.7315 42.3357 48.1487 40.0402 49.1892C37.7805 50.2297 35.3056 50.75 32.6155 50.75ZM32.6693 44.184C34.4627 44.184 36.0588 43.8252 37.4577 43.1076C38.8565 42.3542 40.0222 41.3675 40.9548 40.1476C41.8874 38.8918 42.5868 37.5104 43.0531 36.0035C43.5552 34.4606 43.8063 32.9178 43.8063 31.375C43.8063 29.7245 43.5373 28.1279 42.9993 26.5851C42.4971 25.0422 41.7618 23.6788 40.7934 22.4948C39.8608 21.3108 38.6951 20.36 37.2963 19.6424C35.9333 18.9248 34.391 18.566 32.6693 18.566C30.84 18.566 29.226 18.9427 27.8271 19.6962C26.4641 20.4138 25.3164 21.4005 24.3838 22.6562C23.4512 23.8762 22.7339 25.2396 22.2317 26.7465C21.7654 28.2535 21.5323 29.7963 21.5323 31.375C21.5323 33.0255 21.7834 34.6221 22.2855 36.1649C22.7877 37.7078 23.505 39.0712 24.4376 40.2552C25.406 41.4392 26.5717 42.39 27.9347 43.1076C29.3336 43.8252 30.9118 44.184 32.6693 44.184ZM31.9161 36.3264H38.9103L51.5 50.4809H44.5057L31.9161 36.3264Z"
        fill="white"
      />
    </svg>
  );
};

export default QomekLogo;
