import { CssBaseline, ThemeProvider } from "@mui/material";
import { ENV, isEnviromentProd } from "helpers/enviroments";
import useSettings from "hooks/useSettings";
import { useEffect } from "react";

const MatxTheme = ({ children }) => {
  const { settings } = useSettings();
  let activeTheme = { ...settings.themes[settings.activeTheme] };

  useEffect(() => {
    if (!isEnviromentProd()) {
      document.title = `${ENV} | Admin Qomek`;
    }
  }, []);

  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MatxTheme;
