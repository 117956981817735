import {
  CLEAR_DOC_BY_USERID_AND_DOC_ID,
  CLEAR_USER_BY_ID,
  CLEAR_USERS_BY_PAGE,
  GET_DOC_BY_USERID_AND_DOC_ID,
  GET_USERS_BY_ID,
  GET_USERS_BY_PAGE,
} from "../actions/UsersAction";

const initialState = {
  users: null,
  user: null,
  userDocs: null,
};

const UsersReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS_BY_PAGE: {
      return { ...state, users: action.payload };
    }
    case CLEAR_USERS_BY_PAGE: {
      return { ...state, users: null };
    }
    case GET_USERS_BY_ID: {
      return { ...state, user: action.payload };
    }
    case CLEAR_USER_BY_ID: {
      return { ...state, user: null };
    }
    case GET_DOC_BY_USERID_AND_DOC_ID: {
      return {
        ...state,
        userDocs: state.userDocs
          ? [...state.userDocs, action.payload]
          : [action.payload],
      };
    }
    case CLEAR_DOC_BY_USERID_AND_DOC_ID: {
      return { ...state, userDocs: null };
    }
    default: {
      return { ...state };
    }
  }
};

export default UsersReducer;
