import { CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { FlexAlignCenter } from "./FlexBox";

const StyledLoading = styled(FlexAlignCenter)(() => ({
  position: "fixed",
  top: "0",
  left: "0",
  width: "100vw",
  height: "100vh",
  zIndex: "999",
  background: "#00000020",

  "& img": { width: "auto", height: "25px" },
  "& .circleProgress": {
    position: "absolute",
    left: "50%",
    top: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    color: "#FD385B",
  },
}));

const Loading = () => {
  return (
    <StyledLoading>
      <CircularProgress className="circleProgress" />
    </StyledLoading>
  );
};

export default Loading;
