import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getAnswersToFormByFormId } from "api/content/userform.api";
import PageContainer from "app/pages/components/PageContainer";
import ListLoader from "components/loaders/ListLoader";
import { SmallListTable } from "components/MuiComponents";
import TablePageHeader from "components/TablePageComponents/TablePageHeader";
import { H3 } from "components/Typography";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

const ContentFormsAnswers = () => {
  const { id } = useParams();
  const [answersList, setAnswersList] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const answers = await getAnswersToFormByFormId({ FormId: id });

    setAnswersList(answers);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageContainer>
      <TablePageHeader
        name="Ответы пользователей"
        rowCount={1}
        counterName="Всего ответов"
      />

      <Card sx={{ mt: 4, overflow: "auto" }}>
        {loading ? (
          <ListLoader />
        ) : answersList && answersList?.results?.length > 0 ? (
          <>
            <TableContainer>
              <SmallListTable>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      №
                    </TableCell>
                    <TableCell colSpan={10} align="center">
                      Пользователь
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {answersList.results.map((answer, index) => (
                    <TableRow key={index} hover>
                      <TableCell colSpan={2} align="center">
                        {(answersList?.firstRowOnPage || 1) + index}
                      </TableCell>
                      <TableCell colSpan={10} align="center">
                        <Accordion sx={{ width: "100%" }}>
                          <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            {answer?.profile?.lastName +
                              " " +
                              answer?.profile?.firstName +
                              " " +
                              answer?.profile?.midName}
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer>
                              <SmallListTable>
                                <TableHead>
                                  <TableRow>
                                    <TableCell colSpan={2} align="center">
                                      №
                                    </TableCell>
                                    <TableCell colSpan={5} align="center">
                                      Вопрос
                                    </TableCell>
                                    <TableCell colSpan={5} align="center">
                                      Ответ
                                    </TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {answer?.userFormQuestionsList?.map(
                                    (question, index) => (
                                      <TableRow key={index} hover>
                                        <TableCell colSpan={2} align="center">
                                          {index + 1}
                                        </TableCell>
                                        <TableCell colSpan={5} align="center">
                                          {question?.formQuestion?.description}
                                        </TableCell>
                                        <TableCell colSpan={5} align="center">
                                          {question?.formAnswersViews?.map(
                                            answer => {
                                              return answer?.text;
                                            },
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ),
                                  )}
                                </TableBody>
                              </SmallListTable>
                            </TableContainer>
                          </AccordionDetails>
                        </Accordion>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </SmallListTable>
            </TableContainer>
          </>
        ) : (
          <H3 sx={{ p: 2, mt: 5 }}>{answersList?.message || "Нет ответов"}</H3>
        )}
      </Card>
    </PageContainer>
  );
};

export default ContentFormsAnswers;
