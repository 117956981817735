import React from "react";
import { CircularProgress } from "@mui/material";
import { FlexBox } from "../FlexBox";

const ListLoader = ({ size }) => {
  return (
    <FlexBox sx={{ justifyContent: "center", p: 2 }}>
      <CircularProgress
        size={size}
        color={"error"}
        className="circleProgress"
      />
    </FlexBox>
  );
};

export default ListLoader;
