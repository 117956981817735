import { GET_LAB_COUNT, GET_LAB_SUM } from "../actions/DashboardActions";

const initialState = {
  labSum: null,
  labCount: null,
};

const DashboardReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_LAB_SUM: {
      return { ...state, labSum: action.payload };
    }
    case GET_LAB_COUNT: {
      return { ...state, labCount: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export default DashboardReducer;
