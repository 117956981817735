import { atom } from "recoil";

export const priceGroupsState = atom({
  key: "priceGroups",
  default: [],
});

export const serviceTypesState = atom({
  key: "serviceTypes",
  default: [],
});
