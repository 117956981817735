import { useEffect } from "react";
import useAuth from "hooks/useAuth";
import { instance } from "api";
import { getLocalStorageCredentials } from "../services/identity.service";
import useLogout from "../hooks/useLogout";

const AxiosInterceptor = ({ children }) => {
  const { refresh } = useAuth();
  const logout = useLogout();

  useEffect(() => {
    const responseInterceptor = instance.interceptors.response.use(
      res => res,
      async error => {
        if (error?.response?.status === 401) {
          const credentials = getLocalStorageCredentials();
          if (credentials) {
            await refresh();
          } else {
            logout();
          }
        }

        if (
          error.name === "CanceledError" ||
          error.message === "Network Error"
        ) {
          console.error(error.message, error);
          return;
        }

        return Promise.reject(error);
      },
    );

    return () => instance.interceptors.response.eject(responseInterceptor);
  }, []);

  return children;
};

export default AxiosInterceptor;
