import { atom } from "recoil";

export const currentProfileState = atom({
  key: "currentProfile",
  default: null,
});

export const userAvatarState = atom({
  key: "userAvatar",
  default: "",
});

export const userDetailsState = atom({
  key: "userDetails",
  default: null,
});
