import { Box, styled } from "@mui/material";

const FlexBox = styled(Box)(() => ({ display: "flex" }));

const FlexGap10 = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));

const FlexColumn10 = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "10px",
}));

const FlexBetween = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const FlexBetweenStart = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
}));

const FlexAlignCenter = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const FlexOnlyAlignCenter = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const FlexJustifyEnd = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
}));

const FlexJustifyCenter = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
}));

const FlexJustifyRight = styled(Box)(() => ({
  display: "flex",
  justifyContent: "right",
  columnGap: "10px",
}));

export {
  FlexBox,
  FlexBetween,
  FlexBetweenStart,
  FlexAlignCenter,
  FlexJustifyCenter,
  FlexJustifyRight,
  FlexOnlyAlignCenter,
  FlexJustifyEnd,
  FlexGap10,
  FlexColumn10,
};
