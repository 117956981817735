import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { SettingsProvider } from "contexts/SettingsContext";
import { ru } from "date-fns/locale";
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import routes from "./routes";
import AxiosInterceptor from "./shared/AxiosInterceptor";
import PrefetchProvider from "./shared/PrefetchProvider.jsx";
import { WebSocketProvider } from "./shared/WebSocket";

const App = () => {
  const content = useRoutes(routes);

  return (
    <AxiosInterceptor>
      <WebSocketProvider>
        <PrefetchProvider>
          <SettingsProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ru}
            >
              <MatxTheme>{content}</MatxTheme>
            </LocalizationProvider>
          </SettingsProvider>
        </PrefetchProvider>
      </WebSocketProvider>
    </AxiosInterceptor>
  );
};

export default App;
