import { instance } from "../../../api";

export const GET_APPLICATIONS = "GET_APPLICATIONS";

export const getApplicationsByUser = async UserId => {
  const response = await instance.get(
    `/clinic/api/Applications/search?UserIds=${UserId}`,
  );
  return response.data?.value?.results[0] || null;
};

export const getApplicationsByFilter =
  (
    setLoadingHandler,
    filterParams,
    setSearchParams,
    setPage,
    enqueueSnackbar,
    setApplicationsCount,
    tabs,
  ) =>
  dispatch => {
    setLoadingHandler(true);
    let pages;
    filterParams = filterParams
      .split("&")
      .map(el => {
        if (el.includes("page=")) {
          pages = el.slice(5);
        }
        if (tabs === 2) {
          if (el.includes("Status")) {
            return "Status=0";
          }
        }
        return el;
      })
      .join("&");

    if (tabs === 2 && !filterParams.includes("Status")) {
      filterParams += "&Status=0";
    }

    instance
      .get(
        `/clinic/api/Applications/search?SortType=1&SortBy=Date&${filterParams}`,
      )
      .then(res => {
        setLoadingHandler(false);
        dispatch({ type: GET_APPLICATIONS, payload: res.data.value });
        if (pages > res.data.value.pageCount) {
          let obj = {};
          let filters = filterParams
            .split("&")
            .map(el => {
              obj[el.split("=")[0]] = el.split("=")[1];
              if (el.includes("page=")) {
                obj[el.split("=")[0]] = "0";
                return "page=0";
              } else return el;
            })
            .join("&");
          setPage(0);
          setSearchParams(obj);
          getApplicationsByFilter(setLoadingHandler, filters);
        }
      })
      .catch(() => {
        setLoadingHandler(false);
        enqueueSnackbar("Серверная ошибка");
      });

    getApplicationsCount("Status=0", setApplicationsCount);
  };

export const getApplicationsCount = (filters, setApplications) => {
  instance.get(`/clinic/api/Applications/search?${filters}`).then(res => {
    setApplications(res.data);
  });
};

export const getApplicationByUserId = (
  profileId,
  setLoadingHandler,
  setApplication,
  enqueueSnackbar,
) => {
  setLoadingHandler(true);
  instance
    .get(`/clinic/api/Applications/byProfileId?profileId=${profileId}`)
    .then(res => {
      setApplication(res.data.value);
    })
    .catch(() => {
      if (enqueueSnackbar)
        enqueueSnackbar("Серверная ошибка", { variant: "error" });
    })
    .finally(() => {
      setLoadingHandler(false);
    });
};

export const getApplicationById = (
  id,
  setLoading,
  setApplication,
  enqueueSnackbar,
) => {
  setLoading && setLoading(true);
  instance
    .get(`/clinic/api/Applications/findbyId?applicationId=${id}`)
    .then(res => setApplication(res.data))
    .catch(() => enqueueSnackbar("Серверная ошибка!", { variant: "error" }))
    .finally(() => (setLoading ? setLoading(false) : null));
};

export const postApplicationProfit = (
  body,
  setMoreDetails,
  get,
  enqueueSnackbar,
) => {
  instance
    .post(`/clinic/api/ApplicationDetails`, body)
    .then(res => {
      setMoreDetails(res.data.value);
      enqueueSnackbar("Процент выплат создан!", { variant: "success" });
    })
    .catch(() => null)
    .finally(() => get());
};

export const deleteApplicationProfit = async (id, get, enqueueSnackbar) => {
  instance
    .delete(`/clinic/api/ApplicationDetails?applicationDetailsId=${id}`)
    .then(() =>
      enqueueSnackbar("Процент выплат удален!", { variant: "success" }),
    )
    .finally(() => {
      get();
    });
};

export const getApplicationProfit = (id, setMoreDetails) => {
  instance
    .get(`/clinic/api/ApplicationDetails?applicationId=${id}`)
    .then(res => setMoreDetails(res.data.value))
    .catch(() => null);
};
