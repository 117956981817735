import { StyledEngineProvider } from "@mui/styled-engine";
import { SnackbarProvider } from "notistack";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import App from "./App";
import { Store } from "./app/redux/Store";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});

root.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <Provider store={Store}>
        <SnackbarProvider
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <RecoilRoot>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </RecoilRoot>
        </SnackbarProvider>
      </Provider>
    </BrowserRouter>
  </StyledEngineProvider>,
);
