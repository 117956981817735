import { getLanguages } from "../api/dictionary/language.api";
import { useSetRecoilState } from "recoil";
import { languagesState } from "../store/dictionary.states";

const useGetAuthedData = () => {
  const setLanguages = useSetRecoilState(languagesState);

  return async () => {
    try {
      const languageResponse = await getLanguages();
      setLanguages(languageResponse.results);
    } catch {}
  };
};

export default useGetAuthedData;
