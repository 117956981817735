import { atom } from "recoil";

export const coordinatesState = atom({
  key: "coordinates",
  default: null,
});

export const countriesState = atom({
  key: "countries",
  default: [],
});

export const regionsState = atom({
  key: "regions",
  default: {},
});
