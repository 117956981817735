import { instance } from "../index";

const endpoint = "fileapi/api/FileApi";

export const uploadFile = async (file, params) => {
  const formData = new FormData();
  formData.append("file", file, crypto.randomUUID());

  const response = await instance.post(endpoint, formData, {
    params,
    "Content-Type": "multipart/form-data",
  });
  return response.data;
};

export const deleteFile = async id => {
  const response = await instance.delete(`${endpoint}/delete?id=${id}`);
  return response.data;
};

export const getFile = async id => {
  const response = await instance.get(endpoint, {
    params: { id },
    responseType: "blob",
  });
  return response.data;
};

export const getFileAsBase64 = async id => {
  const response = await instance.get(endpoint, {
    params: { id },
    responseType: "blob",
  });

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(response.data);
    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };
    reader.onerror = error => reject(error);
  });
};

export const getFilesByFilters = async params => {
  const response = await instance.get(`${endpoint}/pagin`, { params });
  return response.data;
};

export const getIcon = async id => {
  const response = await instance.get(`${endpoint}/getIcons`, {
    params: { id },
    responseType: "blob",
  });
  return response.data;
};

export const getArchive = async ids => {
  const response = await instance.post(`${endpoint}/getArchive`, ids);

  return response.data.value;
};
