import { getProfilePartner } from "api/client/profile-partner";
import { getUserClaims } from "api/identity/user-claims.api";
import { getAdminUser } from "api/identity/users.api";
import { getFilteredNavigations } from "layout/navigations";
import { useSetRecoilState } from "recoil";
import { handleGetProviderByProviderType } from "services/generators.service";
import {
  EnumClaimTypes,
  EnumIdentityRoles,
  LS_PROFILE_PARTNER,
} from "services/identity.service";
import {
  adminProfileState,
  profileGeoState,
  profilePartnerState,
  profilePermissionsState,
} from "store/auth.states";
import { currentProfileState } from "store/client.states";
import { navigationsState } from "store/settings.states";

const useCurrentProfileData = () => {
  const setNavigations = useSetRecoilState(navigationsState);
  const setProfileGeo = useSetRecoilState(profileGeoState);
  const setAdminProfile = useSetRecoilState(adminProfileState);
  const setCurrentProfile = useSetRecoilState(currentProfileState);
  const setProfilePartner = useSetRecoilState(profilePartnerState);
  const setProfilePermissions = useSetRecoilState(profilePermissionsState);

  return async profile => {
    const [claims, admin, partnerResponse] = await Promise.all([
      getUserClaims(profile?.userId),
      getAdminUser(profile?.userId),
      getProfilePartner({ UserProfileId: profile.id }),
    ]);

    const isHavePermissionToLogin =
      admin &&
      (admin.role.includes(EnumIdentityRoles.admin) ||
        admin.role.includes(EnumIdentityRoles.manager) ||
        admin.role.includes(EnumIdentityRoles.owner));

    if (isHavePermissionToLogin) {
      setAdminProfile(admin);
    } else {
      throw "Нет доступа";
    }

    if (claims && claims.success) {
      const permissions = claims?.value.filter(
        perm => perm.type === EnumClaimTypes.permission,
      );
      const countryCode = claims?.value.find(
        perm => perm.type === EnumClaimTypes.countryCode,
      )?.value;
      const regionCode = claims?.value.find(
        perm => perm.type === EnumClaimTypes.regionCode,
      )?.value;

      setProfilePermissions(permissions);
      setNavigations(getFilteredNavigations(permissions));
      setProfileGeo({ countryCode, regionCode });
    } else {
      setProfilePermissions([]);
      setNavigations(getFilteredNavigations());
    }

    if (partnerResponse) {
      const profilePartnerData = partnerResponse.results?.find(
        partner => partner.entityType,
      );

      if (profilePartnerData) {
        const profilePartner = {
          provider: await handleGetProviderByProviderType(
            profilePartnerData.entityType,
            profilePartnerData.entityId,
          ),
          providerType: profilePartnerData.entityType,
        };

        setProfilePartner(profilePartner);
        localStorage.setItem(
          LS_PROFILE_PARTNER,
          JSON.stringify(profilePartner),
        );
      }
    }

    setCurrentProfile(profile);
  };
};

export default useCurrentProfileData;
