import { styled, Table } from "@mui/material";

export const HiddenInput = styled("input")(() => ({ display: "none" }));
export const OneLineTextBox = styled("p")(() => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "100%",
}));

export const SmallListTable = styled(Table)(() => ({
  minWidth: 750,
  whiteSpace: "pre",
  // "& th:first-of-type": {paddingLeft: 16},
  // "& th:last-of-type": {paddingRight: 16},
  // "& th:not(:first-of-type)": {paddingLeft: 3, paddingRight: 3},
  // "& th:not(:last-of-type)": {paddingLeft: 3, paddingRight: 3},
  // "& td:none(:first-of-type)": {paddingLeft: 3, paddingRight: 3},
  // "& td:not(:last-of-type)": {paddingLeft: 3, paddingRight: 3},
  "& th": {
    borderBottom: "1px solid rgba(224, 224, 224, 0.8)",
    padding: "15px 5px",
    fontWeight: "bold",
    background: "#1976D220",
  },
  "& th:first-of-type": { borderLeft: "none", width: "60px" },
  "& td": {
    borderBottom: "1px solid rgba(224, 224, 224, 0.8)",
    padding: "5px",
  },
  "& td:first-of-type": { borderLeft: "none", width: "60px" },
}));
