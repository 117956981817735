import {
  CLEAR_PRICES,
  CLEAR_PRICES_BY_TOP,
  CLEAR_SERVICES,
  GET_ALL_GROUPS,
  GET_ALL_PRICES,
  GET_ALL_PRICES_BY_TOP,
  GET_ALL_PROVIDERS,
  GET_ALL_REGIONS,
  GET_ALL_SERVICES,
  GET_REGION_BY_CODE,
} from "../actions/ServicesActions";

const initialState = {
  services: {},
  prices: null,
  pricesByTop: null,
  regions: [],
  providers: [],
  groups: {},
  regionByCode: null,
};

const ServicesReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PRICES: {
      return { ...state, prices: action.data };
    }
    case CLEAR_PRICES: {
      return { ...state, prices: null };
    }
    case GET_ALL_PRICES_BY_TOP: {
      return { ...state, pricesByTop: action.data };
    }
    case CLEAR_PRICES_BY_TOP: {
      return { ...state, pricesByTop: null };
    }
    case GET_ALL_SERVICES: {
      return { ...state, services: action.data };
    }
    case CLEAR_SERVICES: {
      return { ...state, services: {} };
    }
    case GET_ALL_REGIONS: {
      return { ...state, regions: action.data };
    }
    case GET_ALL_PROVIDERS: {
      return { ...state, providers: action.data };
    }
    case GET_ALL_GROUPS: {
      return { ...state, groups: action.data };
    }
    case GET_REGION_BY_CODE: {
      return { ...state, regionByCode: action.data };
    }
    default: {
      return { ...state };
    }
  }
};

export default ServicesReducer;
