import { Box, Card, styled, useMediaQuery } from "@mui/material";

const CardRoot = styled(Card)(() => ({
  height: "100%",
}));

const CardTitle = styled("div")(({ subtitle }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  marginBottom: !subtitle && "16px",
}));

const SimpleCard = ({ children, title, subtitle }) => {
  const isSmallerMD = useMediaQuery(theme => theme.breakpoints.down("md"));

  return (
    <CardRoot
      elevation={6}
      sx={{ padding: isSmallerMD ? "15px" : "20px 24px" }}
    >
      {title && (
        <CardTitle
          subtitle={subtitle}
          sx={{
            fontSize: isSmallerMD ? "15px" : "1rem",
            marginBottom: subtitle ? "0" : isSmallerMD ? "12px" : "16px",
          }}
        >
          {title}
        </CardTitle>
      )}
      {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
      {children}
    </CardRoot>
  );
};

export default SimpleCard;
