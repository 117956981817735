import { combineReducers } from "redux";
import NavigationReducer from "./NavigationReducer";
import UsersReducer from "./UsersReducer";
import OrderReducer from "./OrderReducer";
import FaqReducer from "./FaqReducer";
import LaboratoriesReducer from "./LaboratoriesReducer";
import ServicesReducer from "./ServicesReducer";
import ClinicReducer from "./ClinicReducer";
import MappingReducer from "./MappingReducer";
import DashboardReducer from "./DashboardReducer";
import CampaignsReducer from "./CampaignsReducer";
import ApplicationsReducer from "./ApplicationsReducer";

const RootReducer = combineReducers({
  navigations: NavigationReducer,

  users: UsersReducer,
  orders: OrderReducer,
  faq: FaqReducer,
  labs: LaboratoriesReducer,
  services: ServicesReducer,
  clinics: ClinicReducer,
  mappings: MappingReducer,
  campaigns: CampaignsReducer,
  applications: ApplicationsReducer,
  dashboard: DashboardReducer,
});

export default RootReducer;
