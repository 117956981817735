import { instance } from "../../../api";
import { requestMessageHandler } from "../../utils/utils";

export const GET_MAPPING_SERVICE = "GET_MAPPING_SERVICE";
export const CLEAR_MAPPING_SERVICE = "CLEAR_MAPPING_SERVICE";
export const GET_MAPPING_SERVICE_BY_ID = "GET_MAPPING_SERVICE_BY_ID";
export const CLEAR_MAPPING_SERVICE_BY_ID = "CLEAR_MAPPING_SERVICE_BY_ID";
export const GET_NOT_MAPPING_SERVICE = "GET_NOT_MAPPING_SERVICE";
export const CLEAR_NOT_MAPPING_SERVICE = "CLEAR_NOT_MAPPING_SERVICE";
export const GET_MAPPING_BY_REGION = "GET_MAPPING_BY_REGION";
export const CLEAR_MAPPING_BY_REGION = "CLEAR_MAPPING_BY_REGION";
export const GET_MAPPING_BY_REGION_ID = "GET_MAPPING_BY_REGION_ID";
export const CLEAR_MAPPING_BY_REGION_ID = "CLEAR_MAPPING_BY_REGION_ID";

export const getMappingService = (params, setLoadingHandler) => dispatch => {
  if (setLoadingHandler) setLoadingHandler(true);

  instance
    .get(`/mapping/api/MappingService?${params}`)
    .then(res =>
      dispatch({ type: GET_MAPPING_SERVICE, payload: res.data ?? [] }),
    )
    .catch(error => {
      console.error(error);
      dispatch({ type: GET_MAPPING_SERVICE, payload: [] });
    })
    .finally(() => (setLoadingHandler ? setLoadingHandler(false) : null));
};
export const clearMappingService = () => dispatch => {
  dispatch({ type: CLEAR_MAPPING_SERVICE });
};

export const getMappingServiceById = (id, setLoadingHandler) => dispatch => {
  setLoadingHandler(true);
  instance
    .get(`/mapping/api/MappingService/id?id=${id}`)
    .then(res =>
      dispatch({ type: GET_MAPPING_SERVICE_BY_ID, payload: res.data }),
    )
    .finally(() => setLoadingHandler(false));
};
export const clearMappingServiceById = () => dispatch => {
  dispatch({ type: CLEAR_MAPPING_SERVICE_BY_ID });
};

export const updateMappingService =
  (data, navigateHandler, enqueueSnackbar) => () => {
    instance
      .put(`/mapping/api/MappingService/update`, data)
      .then(() => {
        navigateHandler();
        enqueueSnackbar("Сопоставление отредактировано", {
          variant: "success",
        });
      })
      .catch(e => requestMessageHandler(enqueueSnackbar, e));
  };

export const createMappingService =
  (data, navigateHandler, enqueueSnackbar) => () => {
    instance
      .post(`/mapping/api/MappingService/add`, data)
      .then(() => {
        navigateHandler();
        enqueueSnackbar("Сопоставление создано", { variant: "success" });
      })
      .catch(e => requestMessageHandler(enqueueSnackbar, e));
  };

export const deleteMappingService =
  (data, setButtonLoading, getData, enqueueSnackbar, setConfirmDialogData) =>
  () => {
    setButtonLoading(true);
    instance
      .delete(`/mapping/api/MappingService/delete`, { data: data })
      .then(() => {
        getData(false);
        enqueueSnackbar("Сопоставление удалено", { variant: "success" });
      })
      .catch(e => requestMessageHandler(enqueueSnackbar, e))
      .finally(() => {
        setButtonLoading(false);
        setConfirmDialogData(null);
      });
  };

export const getNotMappingService = (params, setLoadingHandler) => dispatch => {
  setLoadingHandler(true);
  instance
    .get(`/mapping/api/NotMappingService?${params}`)
    .then(res => dispatch({ type: GET_NOT_MAPPING_SERVICE, payload: res.data }))
    .finally(() => setLoadingHandler(false));
};

export const clearNotMappingService = () => dispatch => {
  dispatch({ type: CLEAR_NOT_MAPPING_SERVICE });
};

export const deleteNotMappingService =
  (data, setButtonLoading, getData, enqueueSnackbar, setConfirmDialogData) =>
  () => {
    setButtonLoading(true);
    instance
      .delete(`/mapping/api/NotMappingService/delete`, { data: data })
      .then(() => {
        getData(false);
        enqueueSnackbar("Сопоставления удалено", { variant: "success" });
      })
      .catch(e => requestMessageHandler(enqueueSnackbar, e))
      .finally(() => {
        setConfirmDialogData(null);
        setButtonLoading(false);
      });
  };

export const getMappingByRegion = (params, setLoadingHandler) => dispatch => {
  if (setLoadingHandler) setLoadingHandler(true);
  instance
    .get(`/mapping/api/RegionMapping?${params}`)
    .then(res => dispatch({ type: GET_MAPPING_BY_REGION, payload: res.data }))
    .finally(() => (setLoadingHandler ? setLoadingHandler(false) : null));
};

export const clearMappingByRegion = () => dispatch => {
  dispatch({ type: CLEAR_MAPPING_BY_REGION });
};

export const getMappingRegionById = (id, setLoadingHandler) => dispatch => {
  setLoadingHandler(true);
  instance
    .get(`/mapping/api/RegionMapping/id?id=${id}`)
    .then(res =>
      dispatch({ type: GET_MAPPING_BY_REGION_ID, payload: res.data }),
    )
    .finally(() => setLoadingHandler(false));
};

export const clearMappingRegionById = () => dispatch => {
  dispatch({ type: CLEAR_MAPPING_BY_REGION_ID });
};

export const updateMappingRegion =
  (data, navigateHandler, enqueueSnackbar) => () => {
    instance
      .put(`/mapping/api/RegionMapping/update`, data)
      .then(() => {
        navigateHandler();
        enqueueSnackbar("Сопоставление по региону отредактировано", {
          variant: "success",
        });
      })
      .catch(e => requestMessageHandler(enqueueSnackbar, e));
  };

export const createMappingRegion =
  (data, navigateHandler, enqueueSnackbar) => () => {
    instance
      .post(`/mapping/api/RegionMapping/add`, data)
      .then(() => {
        navigateHandler();
        enqueueSnackbar("Сопоставления по региону создано", {
          variant: "success",
        });
      })
      .catch(e => requestMessageHandler(enqueueSnackbar, e));
  };

export const deleteMappingByRegion =
  (data, getData, enqueueSnackbar, setButtonLoading, setConfirmDialogData) =>
  () => {
    setButtonLoading(true);
    instance
      .delete(`/mapping/api/RegionMapping/delete`, { data: data })
      .then(() => {
        getData();
        enqueueSnackbar("Сопоставления по региону удалено", {
          variant: "success",
        });
      })
      .finally(() => {
        setButtonLoading(false);
        setConfirmDialogData(null);
      });
  };

export const importMappingServiceFile = formData => {
  return instance.post(`/mapping/api/MappingService/importFile`, formData);
};
