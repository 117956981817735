import React from "react";
import { FlexBetween, FlexBox } from "../FlexBox";
import Breadcrumb from "../Breadcrumb";
import { Button } from "@mui/material";
import { handleFormatNumber } from "../../services/formatters.service";
import { useNavigate } from "react-router-dom";

const TablePageHeader = ({
  name,
  counterName,
  rowCount,
  onlyTitle = false,
  showCreateButton = false,
}) => {
  const navigate = useNavigate();

  return (
    <FlexBetween mb={2}>
      <Breadcrumb routeSegments={[{ name }]} onlyTitle={onlyTitle} />

      <FlexBox gap={2}>
        {showCreateButton && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate("create")}
          >
            +
          </Button>
        )}

        <Button color={"primary"} variant="contained">
          {counterName}: {handleFormatNumber(rowCount) ?? 0}
        </Button>
      </FlexBox>
    </FlexBetween>
  );
};

export default TablePageHeader;
