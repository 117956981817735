import { instance } from "../../../api";
import { requestMessageHandler } from "../../utils/utils";

export const GET_ORDERS_BY_PAGE = "GET_ORDERS_BY_PAGE";
export const CLEAR_ORDERS_BY_PAGE = "CLEAR_ORDERS_BY_PAGE";
export const GET_ORDER_BY_ID = "GET_ORDER_BY_ID";
export const CLEAR_ORDER_BY_ID = "CLEAR_ORDER_BY_ID";

export const getOrdersByPage =
  (data, setLoadingHandler, provider) => dispatch => {
    setLoadingHandler(true);
    if (!data?.searchOrder?.userIds?.length) {
      delete data.searchOrder.userIds;
    }

    const filters = { ...data, sortBy: "created", sortType: 1 };

    if (provider) {
      filters.provider = provider;
    }

    instance
      .post(`/order/api/Order/find`, filters)
      .then(res => dispatch({ type: GET_ORDERS_BY_PAGE, data: res.data }))
      .catch(() => null)
      .finally(() => setLoadingHandler(false));
  };
export const clearOrdersByPage = () => dispatch => {
  dispatch({ type: CLEAR_ORDERS_BY_PAGE });
};

export const getOrderById =
  (id, setLoadingHandler, isForOrderList) => dispatch => {
    setLoadingHandler(true);
    const ordersModel = {
      results: [],
      currentPage: 1,
      pageCount: 1,
      pageSize: 10,
      rowCount: 0,
      firstRowOnPage: 1,
      lastRowOnPage: 1,
    };
    instance
      .get(`/order/api/Order/byId?Id=${id}`)
      .then(res => {
        if (isForOrderList) {
          if (res?.data)
            dispatch({
              type: GET_ORDERS_BY_PAGE,
              data: { ...ordersModel, results: [res.data], rowCount: 1 },
            });
        } else dispatch({ type: GET_ORDER_BY_ID, data: res.data });
      })
      .finally(() => setLoadingHandler(false));
  };

export const clearOrderById = () => dispatch => {
  dispatch({ type: CLEAR_ORDER_BY_ID });
};

export const resendResult =
  (orderId, setButtonLoading, enqueueSnackbar) => () => {
    setButtonLoading(true);
    instance
      .get(`/order/api/Order/reSendResult?orderId=${orderId}`)
      .then(() =>
        enqueueSnackbar("Заказ отправлен повторно", { variant: "success" }),
      )
      .catch(e => requestMessageHandler(enqueueSnackbar, e))
      .finally(() => setButtonLoading(false));
  };

export const updateOrderStatus =
  (orderId, status, handleSuccess, enqueueSnackbar) => () => {
    instance
      .put(`/order/api/Order/status?orderId=${orderId}&status=${status}`)
      .then(() => handleSuccess())
      .catch(e => requestMessageHandler(enqueueSnackbar, e));
  };

export const reSendBonuses =
  (orderId, handleSuccess, enqueueSnackbar) => () => {
    instance
      .get(`/order/api/Order/sendBonuses?orderId=${orderId}`)
      .then(() => handleSuccess())
      .catch(e => requestMessageHandler(enqueueSnackbar, e));
  };

export const updatePayStatus =
  (orderId, handleSuccess, enqueueSnackbar) => () => {
    instance
      .post(`/payment/api/Admin/resendStatusPayed?orderId=${orderId}`)
      .then(() => handleSuccess())
      .catch(e => requestMessageHandler(enqueueSnackbar, e));
  };

export const showCheck = (id, status) => {
  instance
    .post(
      `/payment/api/QomekPay/getCheck?typeOfCheck=${
        status === 5 ? "Income" : "IncomeReturn"
      }`,
      {
        id: id,
      },
    )
    .then(res => {
      if (res.data?.value?.model?.id) {
        window.open(
          `https://receipts.ru/${res.data?.value?.model?.id}`,
          "_blank",
        );
      }
    });
};
