import { instance } from "../../../api";
import { requestMessageHandler } from "../../utils/utils";

export const GET_REFERRAL_BY_FILTER = "GET_REFERRAL_BY_FILTER";
export const CLEAR_REFERRAL_BY_FILTER = "CLEAR_REFERRAL_BY_FILTER";
export const GET_SPECIALIZATIONS = "GET_SPECIALIZATIONS";
export const CLEAR_SPECIALIZATIONS = "CLEAR_SPECIALIZATIONS";
export const GET_SPECIALIZATION_BY_ID = "GET_SPECIALIZATION_BY_ID";
export const CLEAR_SPECIALIZATION_BY_ID = "CLEAR_SPECIALIZATION_BY_ID";
export const GET_SPECIALIZATION_TRANSLATES_BY_ID =
  "GET_SPECIALIZATION_TRANSLATES_BY_ID";
export const CLEAR_SPECIALIZATION_TRANSLATES_BY_ID =
  "CLEAR_SPECIALIZATION_TRANSLATES_BY_ID";
export const GET_CONNECT_PROCEDURE_SPEC_BY_SPEC_ID =
  "GET_CONNECT_PROCEDURE_SPEC_BY_SPEC_ID";
export const CLEAR_CONNECT_PROCEDURE_SPEC_BY_SPEC_ID =
  "CLEAR_CONNECT_PROCEDURE_SPEC_BY_SPEC_ID";
export const GET_CONNECT_SYMPTOM_SPEC_BY_SPEC_ID =
  "GET_CONNECT_SYMPTOM_SPEC_BY_SPEC_ID";
export const CLEAR_CONNECT_SYMPTOM_SPEC_BY_SPEC_ID =
  "CLEAR_CONNECT_SYMPTOM_SPEC_BY_SPEC_ID";
export const GET_SYMPTOMS = "GET_SYMPTOMS";
export const CLEAR_SYMPTOMS = "CLEAR_SYMPTOMS";
export const GET_SYMPTOM_BY_ID = "GET_SYMPTOM_BY_ID";
export const CLEAR_SYMPTOM_BY_ID = "CLEAR_SYMPTOM_BY_ID";
export const GET_SYMPTOM_TRANSLATES_BY_ID = "GET_SYMPTOM_TRANSLATES_BY_ID";
export const CLEAR_SYMPTOM_TRANSLATES_BY_ID = "CLEAR_SYMPTOM_TRANSLATES_BY_ID";
export const GET_PROCEDURES = "GET_PROCEDURES";
export const CLEAR_PROCEDURES = "CLEAR_PROCEDURES";
export const GET_PROCEDURE_BY_ID = "GET_PROCEDURE_BY_ID";
export const CLEAR_PROCEDURE_BY_ID = "CLEAR_PROCEDURE_BY_ID";
export const GET_PROCEDURE_TRANSLATES_BY_ID = "GET_PROCEDURE_TRANSLATES_BY_ID";
export const CLEAR_PROCEDURE_TRANSLATES_BY_ID =
  "CLEAR_PROCEDURE_TRANSLATES_BY_ID";

//referrals
export const getReferralByFilter = (params, setLoadingHandler) => dispatch => {
  setLoadingHandler(true);
  instance
    .get(`/clinic/api/Referral/pagedWithFilter?${params}`)
    .then(res => dispatch({ type: GET_REFERRAL_BY_FILTER, payload: res.data }))
    .finally(() => setLoadingHandler(false));
};
export const clearReferralByFilter = () => dispatch => {
  dispatch({ type: CLEAR_REFERRAL_BY_FILTER });
};

//specialization
export const getSpecializations = (filters, setLoadingHandler) => dispatch => {
  if (setLoadingHandler) setLoadingHandler(true);
  instance
    .get(`/clinic/api/Specializations?${new URLSearchParams(filters)}`)
    .then(res => dispatch({ type: GET_SPECIALIZATIONS, payload: res.data }))
    .finally(() => (setLoadingHandler ? setLoadingHandler(false) : null));
};
export const getSpecializationsList = async filters => {
  const response = await instance.get(
    `/clinic/api/Specializations?${new URLSearchParams(filters)}`,
  );
  return response.data;
};

export const clearSpecializations = () => dispatch => {
  dispatch({ type: CLEAR_SPECIALIZATIONS });
};

export const getSpecializationById = (id, setLoadingHandler) => dispatch => {
  if (setLoadingHandler) setLoadingHandler(true);
  instance
    .get(`/clinic/api/Specializations/byId?id=${id}`)
    .then(res =>
      dispatch({ type: GET_SPECIALIZATION_BY_ID, payload: res.data }),
    )
    .finally(() => (setLoadingHandler ? setLoadingHandler(false) : null));
};
export const clearSpecializationById = () => dispatch => {
  dispatch({ type: CLEAR_SPECIALIZATION_BY_ID });
};

export const getSpecializationTranslatesById =
  (id, setLoadingHandler) => dispatch => {
    if (setLoadingHandler) setLoadingHandler(true);
    instance
      .get(
        `/clinic/api/SpecializationLanguage/getBySpecializationId?SpecializationId=${id}`,
      )
      .then(res =>
        dispatch({
          type: GET_SPECIALIZATION_TRANSLATES_BY_ID,
          payload: res.data,
        }),
      )
      .finally(() => (setLoadingHandler ? setLoadingHandler(false) : null));
  };
export const clearSpecializationTranslatesById = () => dispatch => {
  dispatch({ type: CLEAR_SPECIALIZATION_TRANSLATES_BY_ID });
};

export const getConnectSymptomSpec =
  (filter, setLoadingHandler) => dispatch => {
    if (setLoadingHandler) setLoadingHandler(true);
    instance
      .get(`/clinic/api/SymptomSpec/${filter}`)
      .then(res =>
        dispatch({
          type: GET_CONNECT_SYMPTOM_SPEC_BY_SPEC_ID,
          payload: res.data?.value || res.data,
        }),
      )
      .finally(() => (setLoadingHandler ? setLoadingHandler(false) : null));
  };
export const clearConnectSymptomSpec = () => dispatch => {
  dispatch({ type: CLEAR_CONNECT_SYMPTOM_SPEC_BY_SPEC_ID });
};

export const deleteConnectSymptomSpec = id => () => {
  instance.delete(`/clinic/api/SymptomSpec/delete?id=${id}`).then(() => null);
};

export const updateConnectSymptomSpec = data => () => {
  instance.put(`/clinic/api/SymptomSpec/update`, data).then(() => null);
};

export const getConnectProcedureSpec =
  (filter, setLoadingHandler) => dispatch => {
    if (setLoadingHandler) setLoadingHandler(true);
    instance
      .get(`/clinic/api/SpecializationProcedures/${filter}`)
      .then(res =>
        dispatch({
          type: GET_CONNECT_PROCEDURE_SPEC_BY_SPEC_ID,
          payload: res.data?.value || res.data,
        }),
      )
      .finally(() => (setLoadingHandler ? setLoadingHandler(false) : null));
  };
export const clearConnectProcedureSpec = () => dispatch => {
  dispatch({ type: CLEAR_CONNECT_PROCEDURE_SPEC_BY_SPEC_ID });
};

export const createSpecialization =
  (data, onSuccess, enqueueSnackbar) => () => {
    instance
      .post("/clinic/api/Specializations/add", data)
      .then(res => {
        onSuccess(res.data.value.id);
        enqueueSnackbar("Специализация создана!", { variant: "success" });
      })
      .catch(e => requestMessageHandler(enqueueSnackbar, e));
  };

export const updateSpecialization =
  (data, onSuccess, enqueueSnackbar) => () => {
    instance
      .put("/clinic/api/Specializations/update", data)
      .then(() => {
        onSuccess();
        enqueueSnackbar("Специализация обновлена!", { variant: "success" });
      })
      .catch(e => requestMessageHandler(enqueueSnackbar, e));
  };

export const createSpecializationTranslates = (data, enqueueSnackbar) => () => {
  instance
    .post("/clinic/api/SpecializationLanguage/addSpecializationTranslate", data)
    .then(() => null)
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const updateSpecializationTranslates = (data, enqueueSnackbar) => () => {
  instance
    .put("/clinic/api/SpecializationLanguage/update", data)
    .then(() => null)
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const connectSymptomSpec = (data, enqueueSnackbar) => () => {
  instance
    .post(
      `/clinic/api/SymptomSpec/${
        Array.isArray(data?.symptomId) ? "addManySymptoms" : "add"
      }`,
      data,
    )
    .then(() => null)
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const connectProcedureSpec = (data, enqueueSnackbar) => () => {
  instance
    .post(
      `/clinic/api/SpecializationProcedures/${
        Array.isArray(data?.procedureId) ? "addManyProcedures" : "add"
      }`,
      data,
    )
    .then(() => null)
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const deleteConnectProcedureSpec = data => () => {
  instance
    .delete(`/clinic/api/SpecializationProcedures/delete`, { data: data })
    .then(() => null);
};

export const getSpecializationBySymptoms = (id, setData) => () => {
  instance
    .get(`/clinic/api/Specializations/symptoms?symptomId=${id}`)
    .then(res => setData(res.data))
    .catch(() => setData(null));
};

export const getSpecializationByProcedure = (id, setData) => () => {
  instance
    .get(`/clinic/api/Specializations/getByProcedureId?ProcedureId=${id}`)
    .then(res => setData(res.data))
    .catch(() => setData(null));
};

export const deleteSpecializationsById =
  (data, getData, enqueueSnackbar) => () => {
    instance
      .delete(`/clinic/api/Specializations/delete`, { data: data })
      .then(() => {
        getData(true);
        enqueueSnackbar("Специализация удалена", { variant: "success" });
      })
      .catch(e => requestMessageHandler(enqueueSnackbar, e));
  };

//symptoms
export const getSymptoms = (filters, setLoadingHandler) => dispatch => {
  if (setLoadingHandler) setLoadingHandler(true);
  instance
    .get(`/clinic/api/Symptoms?${new URLSearchParams(filters)}`)
    .then(res => dispatch({ type: GET_SYMPTOMS, payload: res.data }))
    .finally(() => (setLoadingHandler ? setLoadingHandler(false) : null));
};
export const clearSymptoms = () => dispatch => {
  dispatch({ type: CLEAR_SYMPTOMS });
};
export const getSymptomById = (setLoadingHandler, id) => dispatch => {
  if (setLoadingHandler) setLoadingHandler(true);
  instance
    .get(`/clinic/api/Symptoms/byId?id=${id}`)
    .then(res => dispatch({ type: GET_SYMPTOM_BY_ID, payload: res.data }))
    .finally(() => (setLoadingHandler ? setLoadingHandler(false) : null));
};
export const clearSymptomById = () => dispatch => {
  dispatch({ type: CLEAR_SYMPTOM_BY_ID });
};

export const createSymptom =
  (data, onSuccess, enqueueSnackbar, setLoading) => () => {
    instance
      .post("/clinic/api/Symptoms/add", data)
      .then(res => {
        onSuccess(res.data.value.id);
        enqueueSnackbar("Симтом успешно создан!", { variant: "success" });
      })
      .catch(e => {
        requestMessageHandler(enqueueSnackbar, e);
        enqueueSnackbar("Ошибка при создании симтома!", { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

export const updateSymptom =
  (data, onSuccess, enqueueSnackbar, setLoading) => () => {
    instance
      .put("/clinic/api/Symptoms/update", data)
      .then(() => {
        onSuccess();
        enqueueSnackbar("Симтом изменен!", { variant: "success" });
      })
      .catch(e => {
        requestMessageHandler(enqueueSnackbar, e);
        enqueueSnackbar("Ошибка при изменении симтома!", { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

export const createSymptomTranslates = (data, enqueueSnackbar) => () => {
  instance
    .post("/clinic/api/SymptomTranslation/addSymptomTranslates", data)
    .then(() => null)
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const updateSymptomTranslates = (data, enqueueSnackbar) => () => {
  instance
    .put("/clinic/api/SymptomTranslation/updateSymptomTranslate", data)
    .then(() => null)
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const getSymptomTranslatesById = (id, setLoadingHandler) => dispatch => {
  if (setLoadingHandler) setLoadingHandler(true);
  instance
    .get(
      `/clinic/api/SymptomTranslation/getAllSymptomTranslatesBySymptomId?SymptomId=${id}`,
    )
    .then(res =>
      dispatch({ type: GET_SYMPTOM_TRANSLATES_BY_ID, payload: res.data }),
    )
    .finally(() => (setLoadingHandler ? setLoadingHandler(false) : null));
};
export const clearSymptomTranslatesById = () => dispatch => {
  dispatch({ type: CLEAR_SYMPTOM_TRANSLATES_BY_ID });
};

export const getSymptomBySpecialization = (id, setData, setLoading) => () => {
  setLoading && setLoading(true);
  instance
    .get(`/clinic/api/Symptoms/bySpecializationId?SpecializationId=${id}`)
    .then(res => setData(res.data.value))
    .catch(() => setData(null))
    .finally(() => [setLoading && setLoading(false)]);
};

export const deleteSymptomsById = (data, getData, enqueueSnackbar) => () => {
  instance
    .delete(`/clinic/api/Symptoms/delete`, { data: data })
    .then(() => {
      getData(true);
      enqueueSnackbar("Симптом удален", { variant: "success" });
    })
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

//procedures
export const getProcedures = (setLoadingHandler, filters) => dispatch => {
  if (setLoadingHandler) setLoadingHandler(true);
  instance
    .get(`/clinic/api/Procedure?${new URLSearchParams(filters)}`)
    .then(res => dispatch({ type: GET_PROCEDURES, payload: res.data }))
    .finally(() => (setLoadingHandler ? setLoadingHandler(false) : null));
};
export const clearProcedures = () => dispatch => {
  dispatch({ type: CLEAR_PROCEDURES });
};
export const getProcedureById = (setLoadingHandler, id) => dispatch => {
  if (setLoadingHandler) setLoadingHandler(true);
  instance
    .get(`/clinic/api/Procedure/getById?procedureId=${id}`)
    .then(res => dispatch({ type: GET_PROCEDURE_BY_ID, payload: res.data }))
    .finally(() => (setLoadingHandler ? setLoadingHandler(false) : null));
};
export const clearProcedureById = () => dispatch => {
  dispatch({ type: CLEAR_PROCEDURE_BY_ID });
};

export const deleteProceduresById = (data, getData, enqueueSnackbar) => () => {
  instance
    .delete(`/clinic/api/Procedure/Delete?procedureId=${data}`)
    .then(() => {
      getData(false);
      enqueueSnackbar("Процедура удалена", { variant: "success" });
    })
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const createProcedure = (data, onSuccess, enqueueSnackbar) => () => {
  instance
    .post("/clinic/api/Procedure/Create", data)
    .then(res => onSuccess(res.data.value.id))
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const updateProcedure = (data, onSuccess, enqueueSnackbar) => () => {
  instance
    .put("/clinic/api/Procedure/Update", data)
    .then(() => onSuccess())
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const createProcedureTranslates = (data, enqueueSnackbar) => () => {
  instance
    .post("/clinic/api/ProcedureLanguages/addProcedureLanguage", data)
    .then(() => null)
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const updateProcedureTranslates = (data, enqueueSnackbar) => () => {
  instance
    .put("/clinic/api/ProcedureLanguages/updateProcedureLanguage", data)
    .then(() => null)
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const getProcedureTranslatesById =
  (id, setLoadingHandler) => dispatch => {
    if (setLoadingHandler) setLoadingHandler(true);
    instance
      .get(
        `/clinic/api/ProcedureLanguages/getAllProcedureTranslateByProcedureId?ProcedureId=${id}`,
      )
      .then(res =>
        dispatch({ type: GET_PROCEDURE_TRANSLATES_BY_ID, payload: res.data }),
      )
      .finally(() => (setLoadingHandler ? setLoadingHandler(false) : null));
  };
export const clearProcedureTranslatesById = () => dispatch => {
  dispatch({ type: CLEAR_PROCEDURE_TRANSLATES_BY_ID });
};
