import { atom } from "recoil";

export const adminProfileState = atom({
  key: "adminProfile",
  default: null,
});

export const profilePermissionsState = atom({
  key: "profilePermissions",
  default: null,
});

export const profileGeoState = atom({
  key: "profileGeo",
  default: null,
});

export const profilePartnerState = atom({
  key: "profilePartner",
  default: null,
});
