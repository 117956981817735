import {
  CLEAR_ORDERS_BY_PAGE,
  GET_ORDERS_BY_PAGE,
  GET_ORDER_BY_ID,
  CLEAR_ORDER_BY_ID,
} from "../actions/OrderAction";

const initialState = {
  orders: null,
  order: null,
};

const OrderReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ORDERS_BY_PAGE: {
      return { ...state, orders: action.data };
    }
    case CLEAR_ORDERS_BY_PAGE: {
      return { ...state, orders: null };
    }
    case GET_ORDER_BY_ID: {
      return { ...state, order: action.data };
    }
    case CLEAR_ORDER_BY_ID: {
      return { ...state, order: null };
    }
    default: {
      return state;
    }
  }
};

export default OrderReducer;
