import { Breadcrumbs, Hidden, Icon, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/system";
import React from "react";
import { NavLink } from "react-router-dom";

const BreadcrumbRoot = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
}));

const BreadcrumbName = styled("h4")(() => ({
  margin: 0,
  paddingBottom: "1px",
  verticalAlign: "middle",
}));

const SubName = styled("span")(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const Separator = styled("h4")(({ theme }) => ({
  margin: 0,
  color: theme.palette.text.hint,
}));

const StyledIcon = styled(Icon)(() => ({
  marginLeft: 8,
  verticalAlign: "middle",
}));

const Breadcrumb = ({ routeSegments, onlyTitle }) => {
  const theme = useTheme();
  const hint = theme.palette.text.hint;

  const isSmallerSM = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <BreadcrumbRoot>
      {routeSegments ? (
        <Hidden xsDown>
          <BreadcrumbName sx={{ fontSize: isSmallerSM ? "14px" : "16px" }}>
            {routeSegments[routeSegments.length - 1]["name"]}
          </BreadcrumbName>
          {!onlyTitle && (
            <Separator
              style={{
                marginLeft: isSmallerSM ? "4px" : "8px",
                paddingBottom: isSmallerSM ? "0" : "3px",
              }}
            >
              |
            </Separator>
          )}
        </Hidden>
      ) : null}
      {!onlyTitle && (
        <Breadcrumbs
          separator={<Icon sx={{ color: hint }}>navigate_next</Icon>}
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            "& .MuiBreadcrumbs-separator": {
              margin: isSmallerSM ? "0 4px" : "0 8px",
            },
          }}
        >
          <StyledIcon
            style={{
              fontSize: isSmallerSM ? "20px" : "24px",
              margin: isSmallerSM ? "0 0 0 4px" : "0 0 4px 8px",
            }}
            color="primary"
          >
            home
          </StyledIcon>
          {routeSegments
            ? routeSegments.map((route, index) => {
                return index !== routeSegments.length - 1 ? (
                  <NavLink key={index} to={route.path}>
                    <SubName sx={{ fontSize: isSmallerSM ? "13px" : "14px" }}>
                      {route.name}
                    </SubName>
                  </NavLink>
                ) : (
                  <SubName
                    sx={{ fontSize: isSmallerSM ? "13px" : "14px" }}
                    key={index}
                  >
                    {route.name}
                  </SubName>
                );
              })
            : null}
        </Breadcrumbs>
      )}
    </BreadcrumbRoot>
  );
};

export default Breadcrumb;
