import { styled } from "@mui/system";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const PageContainer = ({ children, noContainer = false }) => {
  return noContainer ? <>{children}</> : <Container>{children}</Container>;
};

export default PageContainer;
