import { format } from "date-fns";
import { ru } from "date-fns/locale";

export const convertHexToRGB = hex => {
  // check if it's a rgba
  if (hex.match("rgba")) {
    return hex.slice(5).split(",").slice(0, -1).join(",");
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
  }
};

export const flat = array => {
  var result = [];
  array.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flat(a.children));
    }
  });
  return result;
};

export const toDate = (date, time) => {
  if (Date.parse(date)) {
    if (time) {
      return new Intl.DateTimeFormat("ru-Ru", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(date));
    } else {
      return new Intl.DateTimeFormat("ru-Ru", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(new Date(date));
    }
  }
};

export const stringSlicer = (word, amount) => {
  if (word.length > amount) {
    return `${word.slice(0, amount)}...`;
  } else {
    return word;
  }
};

export const genderGenerator = type => {
  switch (type) {
    case 0:
      return "Мужской";
    case 1:
      return "Женский";
    default:
      return "Неизвестно";
  }
};

export const providerTypeGenerator = type => {
  switch (type) {
    case "Lab":
      return "Лаборатория";
    case "Clinic":
      return "Клиника";
    default:
      return "Неизвестно";
  }
};

export const roleGenerator = type => {
  switch (type) {
    case "client":
      return "Клиент";
    case "doctor":
      return "Доктор";
    case "nurse":
      return "Медсестра";
    default:
      return "Неизвестно";
  }
};

export const languageGenerator = type => {
  switch (type) {
    case "ru":
      return "Русский";
    case "en":
      return "Английский";
    case "kz":
      return "Казахский";
    default:
      return "Неизвестно";
  }
};

export const orderStatusGenerator = type => {
  switch (type) {
    case 0:
      return "Зарегистрирован";
    case 1:
      return "Зарегистрирован";
    case 2:
      return "Оплачен";
    case 3:
      return "Оплачен";
    case 4:
      return "Оплачен";
    case 5:
      return "Оплачен";
    case 6:
      return "Результат готов";
    case 7:
      return "Отменен";
    case 8:
      return "В архиве";
    case 9:
      return "Ошибка";
    case 10:
      return "В ожидании оплаты";
    case 11:
      return "В процессе отмены";
    case 12:
      return "Частично готово";
    default:
      return "Неизвестно";
  }
};

// export const orderStatusGenerator = (type) => {
//     switch (type) {
//         case 2:
//             return 'В обработке'
//         case 3:
//             return 'Заказ оплачен'
//         case 5:
//             return 'Заказ оплачен'
//         case 6:
//             return 'Заказ готов'
//         case 7:
//             return 'Заказ отменен'
//         case 9:
//             return 'Ошибка'
//         default:
//             return "Неизвестно"
//     }
// }
export const referralStatusGenerator = type => {
  switch (type) {
    case 0:
      return "Зарегистрировано";
    case 6:
      return "Направление готово";
    case 7:
      return "Отменено";
    case 9:
      return "Ошибка";
    default:
      return "Неизвестно";
  }
};

export const orderColorGenerator = type => {
  switch (type) {
    case 0:
      return "#FFBF00";
    case 1:
      return "#FFBF00";
    case 2:
      return "#3085e1";
    case 3:
      return "#3085e1";
    case 4:
      return "#3085e1";
    case 5:
      return "#3085e1";
    case 6:
      return "#03a621";
    case 7:
      return "#ff0000";
    case 8:
      return "#ff0000";
    case 9:
      return "#ff0000";
    case 10:
      return "#3085e1";
    case 11:
      return "#ff0000";
    case 12:
      return "#949165";
    default:
      return "#808080";
  }
};

export const firstNameLastNameHandler = (firstName, lastName) => {
  if (firstName && lastName) {
    return firstName + " " + lastName;
  } else if (firstName && !lastName) {
    return firstName;
  } else if (!firstName && lastName) {
    return lastName;
  } else {
    return "--- --- ---";
  }
};

export const downloadPdfFile = async file => {
  const base64ToBytes = base64 => {
    const binaryString = window?.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };
  const createPdfBlob = bytes => {
    return new Blob([bytes], { type: "application/pdf" });
  };
  const pdfFile = createPdfBlob(base64ToBytes(file));

  window.open(URL.createObjectURL(pdfFile), "_blank");
};

export const requestMessageHandler = (enqueueSnackbar, error) => {
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    enqueueSnackbar("Ошибка авторизации", { variant: "error" });
  } else if (error.response.data.message) {
    enqueueSnackbar(error.response.data.message, { variant: "error" });
  } else if (!error.response) {
    enqueueSnackbar("Проверьте соединение с интернетом", { variant: "error" });
  } else {
    enqueueSnackbar("Ошибка сервера", { variant: "error" });
  }
};

export const handleIsEqual = (a, b) => {
  if (a instanceof Array && b instanceof Array) {
    if (a.length !== b.length) {
      return false;
    }

    for (var i = 0; i < a.length; i++) {
      if (!handleIsEqual(a[i], b[i])) {
        return false;
      }
    }

    return true;
  }

  return a === b;
};

export const getConstantsValue = (val, arr) => {
  if (val !== undefined && arr) {
    for (let key in arr) {
      if (arr[key]?.value === val) {
        return arr[key];
      }
    }
  } else {
    return null;
  }
};

export const isStateHasFalseHandler = state => {
  for (let key in state) {
    if (state[key]) {
      return false;
    }
  }
  return true;
};

export const isStateHasTrueHandler = state => {
  for (let key in state) {
    if (state[key]) {
      return true;
    }
  }
  return false;
};

export const isStateHasOnlyTrueHandler = state => {
  return !Object.values(state).every(el => el || el === 0);
};

export const isStateHasOnlyNullAndUndefinedHandler = state => {
  return !Object.values(state).every(
    el => el !== null && el !== undefined && JSON.stringify(el) !== "[]",
  );
};

export const setDefaultStateHandler = state => {
  for (let key in state) {
    state[key] = null;
  }
  return state;
};

export const setStartDateHoursHandler = date => {
  return new Date(new Date(date).setHours(0, 0, 0, 0));
};

export const setEndDateHoursHandler = date => {
  return new Date(new Date(date).setHours(23, 59, 59, 99));
};

export const setCustomDate = (date, year, month, day) => {
  return new Date(
    new Date(
      new Date(
        new Date(
          new Date(date).setFullYear(new Date(date).getFullYear() + year),
        ).setMonth(new Date(date).getMonth() + month),
      ).setDate(new Date(date).getDate() + day),
    ).setHours(0, 0, 0, 0),
  );
};

export const symptomTypeGenerator = type => {
  switch (type) {
    case 0:
      return "Детский";
    case 1:
      return "Взрослый";
    case 2:
      return "Общий";
    default:
      return "";
  }
};

export const sortByColumns = (data, key1, key2, type) => {
  if (key1) {
    return data.sort((a, b) => {
      if (key2) {
        if (type) {
          if (isNaN(+a[key1][key2])) {
            return a[key1][key2] > b[key1][key2] ? 1 : -1;
          } else {
            return a[key1][key2] - b[key1][key2];
          }
        } else {
          if (isNaN(+a[key1][key2])) {
            return a[key1][key2] < b[key1][key2] ? 1 : -1;
          } else {
            return b[key1][key2] - a[key1][key2];
          }
        }
      } else {
        if (type) {
          if (isNaN(+a[key1])) {
            return a[key1] > b[key1] ? 1 : -1;
          } else {
            return a[key1] - b[key1];
          }
        } else {
          if (isNaN(+a[key1])) {
            return a[key1] < b[key1] ? 1 : -1;
          } else {
            return b[key1] - a[key1];
          }
        }
      }
    });
  } else {
    return data;
  }
};

export const handleOnChangeRequest = (e, handleFind) => {
  let id = window.setTimeout(function () {}, 0);
  while (id--) {
    window.clearTimeout(id);
  }
  setTimeout(() => {
    if (e.target.value) {
      handleFind();
    }
  }, 500);
};

export const getOrderCurrency = (order, cnt) => {
  let country;
  if (order) {
    if (order.countryCode) {
      country = order.countryCode;
    } else {
      country = order?.labView?.countryCode;
    }
  } else {
    country = cnt;
  }
  switch (country) {
    case "KZ":
      return "тг";
    case "KG":
      return "с";
    default:
      return "тг";
  }
};

export const daysAgoFromTimestamp = effectiveDate => {
  const effectiveDateMs = effectiveDate * 1000;
  const currentDateMs = Date.now();
  const differenceMs = currentDateMs - effectiveDateMs;
  const daysAgo = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  return daysAgo;
};

export function getPercent(partialValue, totalValue) {
  if (totalValue === 0) {
    return 0;
  }

  return (partialValue / totalValue) * 100;
}

export const formatTimestampToDate = timestamp => {
  return format(new Date(timestamp * 1000), "d MMMM yyyy", { locale: ru }); // 18 апреля 2023
};

export const formatToLongMonthDate = date => {
  return format(date, "d MMMM yyyy", { locale: ru }); // 18 апреля 2023
};
