import { instance } from "api";

const serviceEndpoint = "/geo/api/Regions";

export const getAllRegions = async () => {
  const response = await instance.get(serviceEndpoint);
  return response.data;
};

export const getRegionsByCountryCode = async countryCode => {
  const response = await instance.get(
    serviceEndpoint + `/byCountryCode?code=${countryCode}`,
  );
  return response.data;
};
export const getRegionsByCode = async regionCode => {
  const response = await instance.get(
    serviceEndpoint + `/byCode?code=${regionCode}`,
  );
  return response.data;
};
