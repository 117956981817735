import { instance } from "../../../api";
import { requestMessageHandler } from "../../utils/utils";

export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const CLEAR_CAMPAIGNS = "CLEAR_CAMPAIGNS";
export const GET_CAMPAIGN_BY_ID = "GET_CAMPAIGN_BY_ID";
export const CLEAR_CAMPAIGN_BY_ID = "CLEAR_CAMPAIGN_BY_ID";
export const GET_DISCOUNT_AGENT = "GET_DISCOUNT_AGENT";
export const CLEAR_DISCOUNT_AGENT = "CLEAR_DISCOUNT_AGENT";

export const getCampaigns = (setLoadingHandler, page, pageSize) => dispatch => {
  setLoadingHandler(true);
  instance
    .get(`/campaign/api/Campaign?page=${page}&pageSize=${pageSize}`)
    .then(res => dispatch({ type: GET_CAMPAIGNS, payload: res.data }))
    .finally(() => setLoadingHandler(false));
};
export const clearCampaigns = () => dispatch => {
  dispatch({ type: CLEAR_CAMPAIGNS });
};

export const getCampaignById = (setLoadingHandler, campaignId) => dispatch => {
  setLoadingHandler(true);
  instance
    .get(`/campaign/api/Campaign/byCampaignId?Id=${campaignId}`)
    .then(res =>
      dispatch({
        type: GET_CAMPAIGN_BY_ID,
        payload: res.data?.value || null,
      }),
    )
    .finally(() => setLoadingHandler(false));
};
export const clearCampaignById = () => dispatch => {
  dispatch({ type: CLEAR_CAMPAIGN_BY_ID });
};

export const postCampaignCreate = (data, onSuccess, enqueueSnackbar) => () => {
  instance
    .post(`/campaign/api/Campaign/create`, data)
    .then(res => {
      enqueueSnackbar("Скидка создана", { variant: "success" });
      onSuccess(res.data.value.id);
    })
    .catch(e => requestMessageHandler(enqueueSnackbar, e));
};

export const postCampaignConnect = data => () => {
  instance
    .post(`/campaign/api/DiscountAgent`, data)
    .then(() => null)
    .catch(() => null);
};

export const deleteCampaignConnect = id => () => {
  instance
    .delete(`/campaign/api/DiscountAgent?Id=${id}`)
    .then(() => null)
    .catch(() => null);
};

export const putCampaignConnectUpdate = (id, data) => () => {
  instance
    .put(`/campaign/api/DiscountAgent/${id}`, data)
    .then(() => null)
    .catch(() => null);
};

export const putCampaignUpdate =
  (campaignId, onSuccess, enqueueSnackbar, data) => () => {
    instance
      .put(`/campaign/api/Campaign/${campaignId}`, data)
      .then(() => {
        enqueueSnackbar("Скидка отредактирована", { variant: "success" });
        onSuccess();
      })
      .catch(e => requestMessageHandler(enqueueSnackbar, e));
  };

export const getDiscountAgent = (setLoadingHandler, params) => dispatch => {
  setLoadingHandler(true);
  instance
    .get(`/campaign/api/DiscountAgent${params ? `?${params}` : ""}`)
    .then(res => dispatch({ type: GET_DISCOUNT_AGENT, payload: res.data }))
    .finally(() => setLoadingHandler(false));
};
export const clearDiscountAgent = () => dispatch => {
  dispatch({ type: CLEAR_DISCOUNT_AGENT });
};
