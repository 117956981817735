export const handleFormatNumber = (number, isRounded = true) => {
  if (number) {
    if (isRounded && String(number).includes(".")) {
      return number
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  } else {
    return number;
  }
};

export const firstNameLastNameHandler = (
  firstName,
  lastName,
  midName,
  phoneNumber,
) => {
  let fio = "";

  if (lastName) fio += lastName;
  if (firstName) fio += fio ? ` ${firstName}` : firstName;
  if (midName) fio += fio ? ` ${midName}` : midName;

  if (fio) return fio;
  else if (phoneNumber) return phoneNumber;
  else return "--- --- ---";
};

export const formatPhoneNumber = phoneNumber => {
  let formattedNumber = "";

  if (phoneNumber) {
    const digitsOnly = phoneNumber.replace(/\D/g, "");

    if (phoneNumber.startsWith("7")) {
      const countryPrefix = "+7";
      const areaCode = digitsOnly.substr(1, 3);
      const firstPart = digitsOnly.substr(4, 3);
      const secondPart = digitsOnly.substr(7, 2);
      const thirdPart = digitsOnly.substr(9, 2);
      formattedNumber = `${countryPrefix} ${areaCode} ${firstPart}-${secondPart}-${thirdPart}`;
    } else if (phoneNumber.startsWith("996")) {
      const countryPrefix = "+996";
      const areaCode = digitsOnly.substr(3, 3);
      const firstPart = digitsOnly.substr(6, 2);
      const secondPart = digitsOnly.substr(8, 2);
      const thirdPart = digitsOnly.substr(10, 2);
      formattedNumber = `${countryPrefix} ${areaCode} ${firstPart}-${secondPart}-${thirdPart}`;
    } else {
      formattedNumber = phoneNumber;
    }
  }

  return formattedNumber;
};

export const createTitleFromStringArr = stringArr => {
  if (!Array.isArray(stringArr)) {
    return stringArr;
  }

  return stringArr.join(", ");
};
