import { useSetRecoilState } from "recoil";
import { currentProfileState } from "../store/client.states";
import { useNavigate } from "react-router-dom";
import {
  LS_PROFILE_PARTNER,
  LS_REFRESH_TOKEN,
  LS_TOKEN,
} from "../services/identity.service";
import {
  adminProfileState,
  profileGeoState,
  profilePartnerState,
  profilePermissionsState,
} from "../store/auth.states";
import { navigationsState } from "../store/settings.states";
import notificationWebSocket from "api/notifications/notifications.api";

const useLogout = () => {
  const setNavigations = useSetRecoilState(navigationsState);
  const setProfileGeo = useSetRecoilState(profileGeoState);
  const setAdminProfile = useSetRecoilState(adminProfileState);
  const setCurrentProfile = useSetRecoilState(currentProfileState);
  const setProfilePartner = useSetRecoilState(profilePartnerState);
  const setProfilePermissions = useSetRecoilState(profilePermissionsState);

  const navigate = useNavigate();

  return () => {
    setNavigations([]);
    setProfileGeo(null);
    setAdminProfile(null);
    setCurrentProfile(null);
    setProfilePartner(null);
    setProfilePermissions(null);
    notificationWebSocket.close();

    localStorage.removeItem(LS_TOKEN);
    localStorage.removeItem(LS_REFRESH_TOKEN);
    localStorage.removeItem(LS_PROFILE_PARTNER);

    navigate("/login");
  };
};

export default useLogout;
