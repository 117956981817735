import {
  CLEAR_CONNECT_PROCEDURE_SPEC_BY_SPEC_ID,
  CLEAR_CONNECT_SYMPTOM_SPEC_BY_SPEC_ID,
  CLEAR_PROCEDURE_BY_ID,
  CLEAR_PROCEDURE_TRANSLATES_BY_ID,
  CLEAR_PROCEDURES,
  CLEAR_REFERRAL_BY_FILTER,
  CLEAR_SPECIALIZATION_BY_ID,
  CLEAR_SPECIALIZATION_TRANSLATES_BY_ID,
  CLEAR_SPECIALIZATIONS,
  CLEAR_SYMPTOM_BY_ID,
  CLEAR_SYMPTOM_TRANSLATES_BY_ID,
  CLEAR_SYMPTOMS,
  GET_CONNECT_PROCEDURE_SPEC_BY_SPEC_ID,
  GET_CONNECT_SYMPTOM_SPEC_BY_SPEC_ID,
  GET_PROCEDURE_BY_ID,
  GET_PROCEDURE_TRANSLATES_BY_ID,
  GET_PROCEDURES,
  GET_REFERRAL_BY_FILTER,
  GET_SPECIALIZATION_BY_ID,
  GET_SPECIALIZATION_TRANSLATES_BY_ID,
  GET_SPECIALIZATIONS,
  GET_SYMPTOM_BY_ID,
  GET_SYMPTOM_TRANSLATES_BY_ID,
  GET_SYMPTOMS,
} from "../actions/ClinicActions";

const initialState = {
  referrals: null,
  specializations: null,
  specializationById: null,
  specializationTranslatesById: null,
  symptomSpecConnects: null,
  procedureSpecConnects: null,
  symptoms: null,
  symptomById: null,
  symptomTranslatesById: null,
  procedures: null,
  procedureById: null,
  procedureTranslatesById: null,
};

const ClinicReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_REFERRAL_BY_FILTER: {
      return { ...state, referrals: action.payload };
    }
    case CLEAR_REFERRAL_BY_FILTER: {
      return { ...state, referrals: null };
    }
    case GET_SPECIALIZATIONS: {
      return { ...state, specializations: action.payload };
    }
    case CLEAR_SPECIALIZATIONS: {
      return { ...state, specializations: null };
    }
    case GET_SPECIALIZATION_BY_ID: {
      return { ...state, specializationById: action.payload };
    }
    case CLEAR_SPECIALIZATION_BY_ID: {
      return { ...state, specializationById: null };
    }
    case GET_SPECIALIZATION_TRANSLATES_BY_ID: {
      return { ...state, specializationTranslatesById: action.payload };
    }
    case CLEAR_SPECIALIZATION_TRANSLATES_BY_ID: {
      return { ...state, specializationTranslatesById: null };
    }
    case GET_CONNECT_SYMPTOM_SPEC_BY_SPEC_ID: {
      return { ...state, symptomSpecConnects: action.payload };
    }
    case CLEAR_CONNECT_SYMPTOM_SPEC_BY_SPEC_ID: {
      return { ...state, symptomSpecConnects: null };
    }
    case GET_CONNECT_PROCEDURE_SPEC_BY_SPEC_ID: {
      return { ...state, procedureSpecConnects: action.payload };
    }
    case CLEAR_CONNECT_PROCEDURE_SPEC_BY_SPEC_ID: {
      return { ...state, procedureSpecConnects: null };
    }
    case GET_SYMPTOMS: {
      return { ...state, symptoms: action.payload };
    }
    case CLEAR_SYMPTOMS: {
      return { ...state, symptoms: null };
    }
    case GET_SYMPTOM_BY_ID: {
      return { ...state, symptomById: action.payload };
    }
    case CLEAR_SYMPTOM_BY_ID: {
      return { ...state, symptomById: null };
    }
    case GET_SYMPTOM_TRANSLATES_BY_ID: {
      return { ...state, symptomTranslatesById: action.payload };
    }
    case CLEAR_SYMPTOM_TRANSLATES_BY_ID: {
      return { ...state, symptomTranslatesById: null };
    }
    case GET_PROCEDURES: {
      return { ...state, procedures: action.payload };
    }
    case CLEAR_PROCEDURES: {
      return { ...state, procedures: null };
    }
    case GET_PROCEDURE_BY_ID: {
      return { ...state, procedureById: action.payload };
    }
    case CLEAR_PROCEDURE_BY_ID: {
      return { ...state, procedureById: null };
    }
    case GET_PROCEDURE_TRANSLATES_BY_ID: {
      return { ...state, procedureTranslatesById: action.payload };
    }
    case CLEAR_PROCEDURE_TRANSLATES_BY_ID: {
      return { ...state, procedureTranslatesById: null };
    }
    default: {
      return { ...state };
    }
  }
};

export default ClinicReducer;
