import notificationWebSocket from "api/notifications/notifications.api";
import React, { createContext, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { currentProfileState } from "store/client.states";

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const currentProfile = useRecoilValue(currentProfileState);

  const [
    messageFromNotificationWebSocket,
    setMessageFromNotificationWebSocket,
  ] = useState([]);

  useEffect(() => {
    const handleMessage = message => {
      setMessageFromNotificationWebSocket(message);
    };

    currentProfile && notificationWebSocket.connect();

    notificationWebSocket.addMessageHandler(handleMessage);

    return () => {
      notificationWebSocket.removeMessageHandler(handleMessage);
    };
  }, [currentProfile]);

  return (
    <WebSocketContext.Provider value={{ messageFromNotificationWebSocket }}>
      {children}
    </WebSocketContext.Provider>
  );
};
