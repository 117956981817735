export const CLIENT_ID = "admin-client";
export const CLIENT_SECRET = "b2c3a486-7ff2-464f-8a13-8a414573139f";
export const GRANT_TYPE = "password";
export const GRANT_TYPE_REFRESH = "refresh_token";

export const LS_TOKEN = "token";
export const LS_REFRESH_TOKEN = "refreshToken";
export const LS_PROFILE_PARTNER = "ls_partner";

export const EnumIdentityRoles = {
  manager: "manager",
  admin: "admin",
  owner: "owner",
};
export const EnumCreateAdminScopes = {
  general: "admin-api",
  partner: "partner-lab-api",
};

export const EnumClaimTypes = {
  permission: "permission",
  regionCode: "regionCode",
  countryCode: "countryCode",
};

export const EnumAdminStatuses = {
  active: 1,
  inactive: 2,
  blocked: 3,
};

export const adminStatuses = [
  { name: "Активный", value: EnumAdminStatuses.active },
  { name: "Не активный", value: EnumAdminStatuses.inactive },
  { name: "Заблокирован", value: EnumAdminStatuses.blocked },
];

export const handleAdminStatusName = status =>
  adminStatuses.find(adminStatus => adminStatus.value === status)?.name ||
  status ||
  adminStatuses.find(
    adminStatus => adminStatus.value === EnumAdminStatuses.active,
  ).name;

export const handleAdminScopeByProviderType = providerType =>
  providerType ? EnumCreateAdminScopes.general : EnumCreateAdminScopes.general;
// providerType ? EnumCreateAdminScopes.partner : EnumCreateAdminScopes.general;

export const getLocalStorageCredentials = () => {
  const token = localStorage.getItem(LS_TOKEN);
  const refreshToken = localStorage.getItem(LS_REFRESH_TOKEN);

  if (token || refreshToken) {
    return { token, refreshToken };
  } else {
    return null;
  }
};

export const getLocalStorageProvider = () => {
  const provider = localStorage.getItem(LS_PROFILE_PARTNER);

  if (provider) return JSON.parse(provider);
  else return null;
};
