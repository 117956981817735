import {
  CLEAR_LAB_BY_ID,
  CLEAR_LAB_TRANSLATES_BY_ID,
  CLEAR_LABS,
  GET_ALL_LABS,
  GET_LAB_BY_ID,
  GET_LAB_TRANSLATES_BY_ID,
} from "../actions/LaboratoriesActions";

const initialState = {
  labs: null,
  labById: null,
  labTranslatesById: null,
};

const LabsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_LABS: {
      return { ...state, labs: action.data };
    }
    case CLEAR_LABS: {
      return { ...state, labs: null };
    }
    case GET_LAB_BY_ID: {
      return { ...state, labById: action.data };
    }
    case CLEAR_LAB_BY_ID: {
      return { ...state, labById: null };
    }
    case GET_LAB_TRANSLATES_BY_ID: {
      return { ...state, labTranslatesById: action.data };
    }
    case CLEAR_LAB_TRANSLATES_BY_ID: {
      return { ...state, labTranslatesById: null };
    }
    default: {
      return { ...state };
    }
  }
};

export default LabsReducer;
