import { isEnviromentProd } from "helpers/enviroments";
import { useRecoilValue } from "recoil";
import { restrictedUserClaimCode } from "services/permissions.service";
import { profilePermissionsState } from "store/auth.states";
import NotFound from "../pages/NotFound";

const ScopeLayout = ({
  children,
  isRequiredKey,
  permissionKey,
  elseReturnNull,
  orPermissionKey,
  elseReturnCustomNode,
}) => {
  const permissions = useRecoilValue(profilePermissionsState);

  if (isRequiredKey && isEnviromentProd()) {
    if (
      permissions?.some(
        claim =>
          claim.value === permissionKey || claim.value === orPermissionKey,
      )
    )
      return children;
  } else {
    if (
      permissions?.every(claim => claim.value !== restrictedUserClaimCode) ||
      permissions?.some(
        claim =>
          claim.value === permissionKey || claim.value === orPermissionKey,
      )
    )
      return children;
  }

  if (elseReturnNull) return null;
  else if (elseReturnCustomNode) return elseReturnCustomNode;
  else return <NotFound />;
};

export default ScopeLayout;
